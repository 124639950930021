import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Input, Label, Textarea } from 'theme-ui';
import usePrizeDraws from '../../../hooks/usePrizeDraws';
import useRafflePrizes from '../../../hooks/useRafflePrizes';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import SelectOne from '../../segments/SelectOne';
import EditorSaveButton from '../../segments/EditorSaveButton';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';

const PrizeDrawsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		savePrizeDraw,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = usePrizeDraws();

	const {
		getRafflePrizes,
		rafflePrizes,
		error: rafflePrizeError,
		isProcessing: isProcessingRafflePrizes
	} = useRafflePrizes();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [hasRequestedPrizes, setHasRequestedPrizes] = useState(false);

	useEffect(() => {
		if (!hasRequestedPrizes) {
			setHasRequestedPrizes(true);
			getRafflePrizes({ isGrandPrize: true });
		}
	}, [hasRequestedPrizes, getRafflePrizes, setHasRequestedPrizes]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		savePrizeDraw(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Grand Prize Draw:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Label htmlFor='name'>PrizeDraw Name</Label>
					<Input
						name='name'
						mb={3}
						value={localData?.name || ''}
						onChange={onFieldUpdate}
					/>
					<Label htmlFor='rafflePrize'>Grand Prize to give away</Label>
					<SelectOne
						data={rafflePrizes}
						value={localData?.rafflePrize}
						name='rafflePrize'
						onFieldUpdate={onFieldUpdate}
						noDataMsg='No prizes found to choose from.'
					/>
					<Label htmlFor='description'>Description</Label>
					<Textarea
						rows={4}
						name='description'
						mb={3}
						value={localData?.description || ''}
						onChange={onFieldUpdate}
					/>
					<Label htmlFor='prizeDrawDate'>Prize Draw Date</Label>
					<DatePicker
						value={localData?.prizeDrawDate}
						name='prizeDrawDate'
						onFieldUpdate={onFieldUpdate}
					/>
					<EditorSaveButton
						status={localSaveResult?.status}
						isProcessing={isProcessing}
						label='Prize Draw'
						uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
						setLocalData={setLocalData}
						setLocalSaveResult={setLocalSaveResult}
					/>
					<UiStatusMessageDisplay
						message={uiStatusMessageSaveResult}
						consumed={uiStatusSaveMessageConsumed}
					/>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default PrizeDrawsAdd;
