import { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	getProducts as _getProducts,
	setSelectedProductId,
	loadProduct,
	addProduct,
	updateProduct,
	deleteProduct as _deleteProduct,
	productSaveError,
	productClearSaveResult,
	productClearDeleteResult
} from '../redux/actions/products';
import { UI_STATUS_MESSAGE_TIMEOUT } from '../constants';

let saveMessageDisplayTimeout = null;
let deleteMessageDisplayTimeout = null;

const useProducts = () => {
	const products = useSelector(state => state.products?.list, shallowEqual);
	const currentProduct = useSelector(state => state.products?.currentProduct);
	const selectedProductId = useSelector(state => state.products?.selectedProductId);
	const error = useSelector(state => state.products?.loadError);
	const saveResult = useSelector(state => state.products?.saveResult);
	const deleteResult = useSelector(state => state.products?.deleteResult);
	const isProcessing = useSelector(state => state.products?.isRequesting);
	// const [localSaveResult, setLocalSaveResult] = useState(null);
	const dispatch = useDispatch();

	const validateData = data => {
		return true;
		// return data && data.product && data.car;
	};

	const packExtras = data => {
		data.extras = [];
		if (data.raffles) {
			const raffleCount = parseInt(data.raffles);
			raffleCount &&
				data.extras.push({
					type: 'raffles',
					count: raffleCount
				});
			delete data.raffles;
		}

		if (data.jackpot) {
			const jackpotCount = parseInt(data.jackpot);
			jackpotCount &&
				data.extras.push({
					type: 'jackpot',
					count: jackpotCount
				});
			delete data.jackpot;
		}

		return data;
	};

	const unpackExtras = data => {
		if (data?.extras?.length) {
			for (const extra of data.extras) {
				data[extra.type] = extra.count;
			}
			delete data.extras;
		}
		return data;
	};

	const getProducts = useCallback(() => {
		dispatch(_getProducts());
	}, [dispatch]);

	const getProduct = useCallback(
		id => {
			if (id) {
				dispatch(setSelectedProductId(id));
				dispatch(loadProduct({ id }));
			}
		},
		[dispatch]
	);

	const selectProduct = useCallback(
		id => {
			dispatch(setSelectedProductId(id));
		},
		[dispatch]
	);

	const deleteProduct = useCallback(
		id => {
			dispatch(_deleteProduct(id));
		},
		[dispatch]
	);

	const saveProduct = useCallback(
		(id, data) => {
			if (validateData(data)) {
				data.priceCents = parseInt(data.priceCents);
				const dataReady = packExtras(data);
				if (id) {
					return dispatch(updateProduct(id, dataReady));
				}
				return dispatch(addProduct(dataReady));
			}
			dispatch(productSaveError('invalid data'));
		},
		[dispatch]
	);

	const uiStatusSaveMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (saveResult.status !== null && (!timeout_ms || !saveMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(productClearSaveResult());
				clearTimeout(saveMessageDisplayTimeout);
				saveMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (saveMessageDisplayTimeout) {
					clearTimeout(saveMessageDisplayTimeout);
				}
				consumed();
			} else {
				saveMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	const uiStatusDeleteMessageConsumed = (timeout_ms = UI_STATUS_MESSAGE_TIMEOUT) => {
		if (deleteResult.status !== null && (!timeout_ms || !deleteMessageDisplayTimeout)) {
			const consumed = () => {
				dispatch(productClearDeleteResult());
				clearTimeout(deleteMessageDisplayTimeout);
				deleteMessageDisplayTimeout = null;
			};
			if (timeout_ms === false) {
				if (deleteMessageDisplayTimeout) {
					clearTimeout(deleteMessageDisplayTimeout);
				}
				consumed();
			} else {
				deleteMessageDisplayTimeout = setTimeout(consumed, timeout_ms);
			}
		}
	};

	return {
		getProducts,
		getProduct,
		saveProduct,
		selectProduct,
		deleteProduct,
		unpackExtras,
		isProcessing,
		currentProduct,
		selectedProductId,
		products,
		uiStatusMessageSaveResult: saveResult,
		uiStatusMessageDeleteResult: deleteResult,
		uiStatusDeleteMessageConsumed,
		uiStatusSaveMessageConsumed,
		error
	};
};

export default useProducts;
