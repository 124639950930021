/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	useParams,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import '../Datasource.scss';

import useProducts from '../../../hooks/useProducts';
import { cleanDataMethod } from '../../../utils/datasource';

import { DatasourceContainer } from '../../ThemeUiStyled';
import ActionButtons from './ActionButtons';
import List from './List';
import View from './View';
import Edit from './Edit';
import Add from './Add';

const Products = props => {
	// const dispatch = useDispatch();
	const params = useParams();
	const { oMethod = 'list', _id = null } = params;
	const {
		getProducts,
		products,
		currentProduct,
		selectedProductId,
		selectProduct,
		error,
		unpackExtras
	} = useProducts();

	const [hasRequestedProducts, setHasRequestedProducts] = useState(false);
	const [myProductId, setMyProductId] = useState(selectedProductId);
	const [method, setMethod] = useState(oMethod);

	useEffect(() => {
		if (method === 'list' && !hasRequestedProducts) {
			setHasRequestedProducts(true);
			getProducts();
		}
	}, [method, hasRequestedProducts, getProducts]);

	useEffect(() => {
		setMethod(cleanDataMethod(oMethod));
	}, [oMethod]);

	useEffect(() => {
		console.log('>>>> check currentProduct, method: ' + method);
		if (
			['view', 'edit'].indexOf(method) > -1 &&
			(!currentProduct || currentProduct._id !== _id)
		) {
			console.log('set it! ' + _id);
			selectProduct(_id);
			setMyProductId(_id);
		}
	}, [selectedProductId, _id, currentProduct, method, selectProduct]);

	// HINT: could handle any error={error} instead of rendering anything at all??
	// or maybe each component is better at handling own error with contextual controls

	return (
		<DatasourceContainer>
			<h1
				sx={{
					mb: '0.25em'
				}}>
				Manage Products
			</h1>
			<ActionButtons method={method} />
			{method === 'list' && <List data={products} error={error} />}
			{method === 'view' && <View data={currentProduct} error={error} />}
			{method === 'edit' && <Edit data={unpackExtras(currentProduct)} error={error} />}
			{method === 'add' && <Add data={{ enabled: true }} />}
		</DatasourceContainer>
	);
};

export default Products;
