import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useProducts from '../../../hooks/useProducts';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const ProductsView = props => {
	const { data, error } = props;
	const { selectedProductId, getProduct } = useProducts();

	console.log('ProductsView');
	console.log(selectedProductId, data, error);

	useEffect(() => {
		if (!data && selectedProductId) {
			console.log('getProduct!!!!' + selectedProductId);
			getProduct(selectedProductId);
		}
	}, [data, selectedProductId, getProduct]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading product group....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Product Group:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.label}</td>
					</tr>
					<tr>
						<td>Venue: </td>
						<td>{data.venueName}</td>
					</tr>
					<tr>
						<td>Address: </td>
						<td>
							{data.addrLine1}
							{data.addrLine2 ? (
								<>
									<br />
									{data.addrLine2}
								</>
							) : null}
						</td>
					</tr>
					<tr>
						<td>City: </td>
						<td>{data.city}</td>
					</tr>
					<tr>
						<td>State: </td>
						<td>{data.state}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default ProductsView;
