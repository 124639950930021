/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Box, Button, Flex, Input, Label, Spinner, Textarea } from 'theme-ui';
import useEvents from 'hooks/useEvents';
import useProducts from 'hooks/useProducts';
import purchaseEmailGenerator from 'utils/purchaseEmailGenerator';
import SelectOne from 'components/segments/SelectOne';
import { getOnFieldUpdate } from 'utils/datasource';
import useComms from 'hooks/useComms';

const quantityOptions = [0, 1, 2, 3, 4, 5].map(quan => ({
	name: quan,
	value: quan
}));

const EmailBody = ({ emailBody }) => {
	return (
		<>
			<p>
				Thanks for participating in the{' '}
				<strong>Legends of LPGA Celebrity Charity Golf Tournament</strong> on behalf of
				Grateful Hearts.
			</p>
			{emailBody}

			<p>
				Thank You for your donation. If you have any questions please feel free to contact
				us at <a href='tel:725-229-9100'>725-229-9100</a> or email us at{' '}
				<a href='mailto:info@allgratefulhearts.org'>info@allgratefulhearts.org</a>
			</p>

			<p>Sincerely,</p>
			<p>
				Jay Dingle
				<br />
				CEO
			</p>
		</>
	);
};

const ProductEmailGenerator = () => {
	const { products, getProducts } = useProducts();
	const { events, getEvents } = useEvents();

	const [selectedProducts, setSelectedProducts] = useState({});
	const [selectedQuantities, setSelectedQuantities] = useState({});

	const [selectedMyStuff, setSelectedMyStuff] = useState({});
	const [selectedMyStuffQuantities, setSelectedMyStuffQuantities] = useState({});

	const {
		sendDirectEmail,
		isProcessing,
		emailMessageSendResponse,
		emailMessageSendResponseError
	} = useComms();

	const [localData, setLocalData] = useState({});

	const items = useMemo(() => {
		if (Object.keys(selectedProducts).length) {
			return Object.keys(selectedProducts).map(productId => {
				const product = products.find(p => p._id === productId);
				return {
					productId,
					quantity: parseInt(selectedQuantities[productId]) || 0
				};
			});
		}

		return [];
	}, [selectedProducts, selectedQuantities, products]);

	const myStuffItems = useMemo(() => {
		if (Object.keys(selectedMyStuff).length) {
			return Object.keys(selectedMyStuff).map(productId => {
				const product = products.find(p => p._id === productId);
				return {
					productId,
					quantity: parseInt(selectedMyStuffQuantities[productId]) || 0
				};
			});
		}

		return [];
	}, [selectedMyStuff, selectedMyStuffQuantities, products]);

	const emailBody = useMemo(() => {
		const itemIds = items.map(i => i.productId);
		const myStuffIds = myStuffItems.map(i => i.productId);
		const myProducts = products.filter(
			p => itemIds.includes(p._id) || myStuffIds.includes(p._id)
		);
		const requiredProductIds = new Set();

		for (const product of myProducts) {
			if (product.requiredProducts?.length) {
				for (const id of product.requiredProducts) {
					requiredProductIds.add(id);
				}
			}
		}

		const myRequiredProducts = products.filter(p => requiredProductIds.has(p._id));
		const eventIds = myProducts?.map(p => p.eventId) || [];
		const myEvents = events.filter(e => eventIds.includes(e._id));

		return purchaseEmailGenerator(
			items,
			myStuffItems,
			[...myProducts, ...myRequiredProducts],
			myEvents
		);
	}, [items, myStuffItems, products, events]);

	useEffect(() => {
		if (!events?.length) {
			getEvents();
		}
	}, [events, getEvents]);

	useEffect(() => {
		if (!products?.length) {
			getProducts();
		}
	}, [products, getProducts]);

	const handleSelectQuantity = (productId, quantity, isMyStuff) => {
		if (isMyStuff) {
			setSelectedMyStuffQuantities(prevState => {
				return {
					...prevState,
					[productId]: quantity
				};
			});
		} else {
			setSelectedQuantities(prevState => {
				return {
					...prevState,
					[productId]: quantity
				};
			});
		}
	};

	const handleSelectProduct = e => {
		const productId = e.target.value;
		if (selectedProducts[productId]) {
			setSelectedProducts(prevState => {
				const localState = { ...prevState };
				delete localState[productId];
				return localState;
			});

			handleSelectQuantity(productId, '0');

			return;
		}
		setSelectedProducts(prevState => {
			return {
				...prevState,
				[productId]: true
			};
		});

		handleSelectQuantity(productId, '1');
	};

	const handleSelectMyStuff = e => {
		const productId = e.target.value;
		if (selectedMyStuff[productId]) {
			setSelectedMyStuff(prevState => {
				const localState = { ...prevState };
				delete localState[productId];
				return localState;
			});

			handleSelectQuantity(productId, '0', true);

			return;
		}
		setSelectedMyStuff(prevState => {
			return {
				...prevState,
				[productId]: true
			};
		});

		handleSelectQuantity(productId, '1', true);
	};

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const [emailLocalError, setEmailLocalError] = useState('');

	const sendEmail = e => {
		e.preventDefault();

		if (!localData?.email || !localData?.subject) {
			setEmailLocalError('Please add an email and subject to test send with.');
			return;
		}

		const body = ReactDOMServer.renderToStaticMarkup(<EmailBody emailBody={emailBody} />);

		console.log('email body generated');
		console.log(body);

		setEmailLocalError('');
		sendDirectEmail(body, localData.email, localData.subject);
	};

	if (!events?.length) {
		return (
			<div>
				<h1>Loading Events</h1>
			</div>
		);
	}

	if (!products?.length) {
		return (
			<div>
				<h1>Loading Products</h1>
			</div>
		);
	}

	const eventProducts = products.filter(
		product => product.pType === 'event-ticket' || product.pType === 'donation-raffle'
	);

	return (
		<div>
			<h2>Purchase Email Generator</h2>
			<Flex
				style={{
					gap: 20,
					flexDirection: 'row',
					marginRight: '80px'
					// alignItems: 'center'
					// justifyContent: 'center'
				}}>
				<Box sx={{ flex: 0.75 }}>
					<h2>Pick products and set quantities:</h2>
					<table>
						<tbody>
							{eventProducts.map((product, idx) => {
								const myEvent = events.find(event => event._id === product.eventId);

								return (
									<tr
										key={idx}
										style={{
											marginBottom: '4px',
											paddingBottom: '4px',
											borderBottom: '1px solid #dfdfdf'
										}}>
										<td>
											<input
												type='checkbox'
												name={product._id}
												onChange={handleSelectProduct}
												value={product._id}
												checked={selectedProducts[product._id] || false}
											/>
										</td>
										<td>
											<p
												sx={{
													m: 0,
													p: 0,
													pr: '8px'
												}}>
												{product.label}
											</p>
										</td>
										<td>
											{selectedProducts[product._id] && (
												<Flex style={{ flexDirection: 'row', gap: 4 }}>
													<span>Quantity: </span>
													<SelectOne
														_sx={{
															p: 0,
															px: '8px',
															m: 0,
															width: '64px'
														}}
														data={quantityOptions}
														value={selectedQuantities[product._id]}
														dataValueProp='value'
														name={`quantity-${product._id}`}
														onFieldUpdate={e =>
															handleSelectQuantity(
																product._id,
																e.target.value
															)
														}
														useEmptySelector={false}
														// noDataMsg='No product types found to choose from.'
													/>
												</Flex>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<h2>Products already purchased:</h2>
					<table>
						<tbody>
							{eventProducts.map((product, idx) => {
								const myEvent = events.find(event => event._id === product.eventId);

								return (
									<tr
										key={idx}
										style={{
											marginBottom: '4px',
											paddingBottom: '4px',
											borderBottom: '1px solid #dfdfdf'
										}}>
										<td>
											<input
												type='checkbox'
												name={product._id}
												onChange={handleSelectMyStuff}
												value={product._id}
												checked={selectedMyStuff[product._id] || false}
											/>
										</td>
										<td>
											<p
												sx={{
													m: 0,
													p: 0,
													pr: '8px'
												}}>
												{product.label}
											</p>
										</td>
										<td>
											{selectedMyStuff[product._id] && (
												<Flex style={{ flexDirection: 'row', gap: 4 }}>
													<span>Quantity: </span>
													<SelectOne
														_sx={{
															p: 0,
															px: '8px',
															m: 0,
															width: '64px'
														}}
														data={quantityOptions}
														value={
															selectedMyStuffQuantities[product._id]
														}
														dataValueProp='value'
														name={`quantity-${product._id}`}
														onFieldUpdate={e =>
															handleSelectQuantity(
																product._id,
																e.target.value,
																true
															)
														}
														useEmptySelector={false}
														// noDataMsg='No product types found to choose from.'
													/>
												</Flex>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<h2>Send a test email:</h2>

					<Box as='form' onSubmit={sendEmail}>
						<Label htmlFor='email'>Email Address</Label>
						<Input
							name='email'
							mb={3}
							value={localData?.email || ''}
							onChange={onFieldUpdate}
						/>
						<Label htmlFor='subject'>Subject</Label>
						<Input
							name='subject'
							mb={3}
							value={localData?.subject || ''}
							onChange={onFieldUpdate}
						/>

						{isProcessing ? <Spinner /> : <Button>Send Test Email</Button>}

						{emailMessageSendResponse?.data && (
							<p>{JSON.stringify(emailMessageSendResponse.data)}</p>
						)}
						{emailMessageSendResponseError && (
							<p sx={{ color: 'red' }}>
								{JSON.stringify(emailMessageSendResponseError)}
							</p>
						)}

						{emailLocalError && <div>{emailLocalError}</div>}
					</Box>
				</Box>
				<Box sx={{ flex: 1 }}>
					<h2>Email body to send:</h2>
					<EmailBody emailBody={emailBody} />
				</Box>
			</Flex>
		</div>
	);
};

export default ProductEmailGenerator;
