import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useEvents from '../../../hooks/useEvents';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const EventsView = props => {
	const { data, error } = props;
	const { selectedEventId, getEvent } = useEvents();

	console.log('EventsView');
	console.log(selectedEventId, data, error);

	useEffect(() => {
		if (!data && selectedEventId) {
			console.log('getEvent!!!!' + selectedEventId);
			getEvent(selectedEventId);
		}
	}, [data, selectedEventId, getEvent]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading event....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Event:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.label}</td>
					</tr>
					<tr>
						<td>Venue: </td>
						<td>{data.venueName}</td>
					</tr>
					<tr>
						<td>Address: </td>
						<td>
							{data.addrLine1}
							{data.addrLine2 ? (
								<>
									<br />
									{data.addrLine2}
								</>
							) : null}
						</td>
					</tr>
					<tr>
						<td>City: </td>
						<td>{data.city}</td>
					</tr>
					<tr>
						<td>State: </td>
						<td>{data.state}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default EventsView;
