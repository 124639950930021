import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Flex, Input, Label, Textarea } from 'theme-ui';
import useDrivers from '../../../hooks/useDrivers';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const DriversAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveDriver,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useDrivers();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveDriver(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Driver:</h2>
			<p>dev note: will be able to link these to real user accounts eventually.</p>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Driver Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='carNumber'>Preferred Car Number</Label>
							<Input
								name='carNumber'
								mb={3}
								value={localData?.carNumber || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='iRacingId'>iRacing ID</Label>
							<Input
								name='iRacingId'
								mb={3}
								value={localData?.iRacingId || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='iRatingOnRoad'>iRating on Road</Label>
							<Input
								name='iRatingOnRoad'
								mb={3}
								value={localData?.iRatingOnRoad || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='raceTeam'>Race Team</Label>
							<Input
								name='raceTeam'
								mb={3}
								value={localData?.raceTeam || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='twitter'>Twitter Handle</Label>
							<Input
								name='twitter'
								mb={3}
								value={localData?.twitter || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='country'>Country</Label>
							<Input
								name='country'
								mb={3}
								value={localData?.country || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='equipment'>Equipment</Label>
							<Textarea
								name='equipment'
								mb={3}
								value={localData?.equipment || ''}
								rows={4}
								onChange={onFieldUpdate}
							/>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Driver'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
			<pre>{JSON.stringify(localData, null, 4)}</pre>
		</div>
	);
};

export default DriversAdd;
