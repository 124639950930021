/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useRaces from '../../../hooks/useRaces';
import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useJackpotResults from '../../../hooks/useJackpotResults';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { ListTable } from '../../ThemeUiStyled';
import { RACETYPES } from '../../../constants';
import { showDate } from '../../../utils/datasource';

const JackpotResultsList = props => {
	const { data, error } = props;
	const {
		selectJackpotResult,
		// deleteJackpotResult,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useJackpotResults();
	const history = useHistory();

	const { getRaces, races, error: raceError, isProcessing: isProcessingRaces } = useRaces();

	// const [jackpotResultToDelete, setJackpotResultToDelete] = useState(null);
	// const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	const [hasRequestedRaces, setHasRequestedRaces] = useState(false);

	useEffect(() => {
		if (!hasRequestedRaces) {
			setHasRequestedRaces(true);
			getRaces({ raceType: RACETYPES.GHA_JACKPOT });
		}
	}, [hasRequestedRaces, getRaces, setHasRequestedRaces]);

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>No data found, add something to the list.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting jackpotResults....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectJackpotResult(item._id);
		history.push('/data/jackpot-results/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectJackpotResult(item._id);
		history.push('/data/jackpot-results/edit/' + item._id);
	};

	const pickWinners = item => {
		console.log('pickWinner');
		selectJackpotResult(item._id);
		history.push('/data/jackpot-results/pick-winners/' + item._id);
	};

	// const deleteSelectedItem = item => {
	// 	setJackpotResultToDelete(item);
	// 	setShowDeleteModal(true);
	// };

	// const rejectDelete = () => {
	// 	setJackpotResultToDelete(null);
	// 	setShowDeleteModal(false);
	// };

	// const confirmDelete = () => {
	// 	jackpotResultToDelete && deleteJackpotResult(jackpotResultToDelete._id);
	// 	setJackpotResultToDelete(null);
	// 	setShowDeleteModal(false);
	// };

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '2px 5px',
		fontSize: '12px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getRaceName = id => {
		const myRace = races?.find(R => R._id === id);
		return myRace?.name || id;
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						pickWinners(item);
					}}>
					Pick Winners
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				{/*
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='jackpotResult'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
				*/}
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Date</td>
						<td>Race</td>
						<td>Picks</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{showDate(item.jackpotDate || 'none')}</td>
								<td>{getRaceName(item.race || 'no race found')}</td>
								<td>{item.selectedNumbers?.join(', ') || 'no results yet'}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default JackpotResultsList;
