/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import {
	// BrowserRouter as Router,
	// Switch,
	// Route,
	Link
} from 'react-router-dom';
import styled from 'styled-components';
import './AppMenu.scss';

const MenuContainer = styled.div`
	margin: 0 auto;
	width: 240px;
	max-width: 100%;
	border: 1px solid right;
	/*
    @media (max-width: 600px) {
        width: 95%;
    }
    */
`;

const MenuHeader = styled.div`
	padding: 0 8px;
	margin-bottom: 0.3em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 0.8em;
	font-weight: bold;
	background-color: ${props => props.theme.colors.primary};
	color: ${props => props.theme.colors.background};
`;

const MenuItems = styled.div``;

const AppMenu = props => {
	const { theme } = useThemeUI();

	const dataLinkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		mb: '0.3em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<MenuContainer>
			<h3>Admin Menu:</h3>
			<MenuHeader theme={theme}>Tools</MenuHeader>
			<div>
				<Link sx={dataLinkSx} to='/tools/comms'>
					Communication Center
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/tools/purchase-email-generator'>
					Purchase Email Response Tester
				</Link>
			</div>
			<MenuHeader theme={theme}>Ultimus</MenuHeader>
			<div>
				<Link sx={dataLinkSx} to='/data/events'>
					Events
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/products'>
					Products
				</Link>
			</div>
			<MenuHeader theme={theme}>G-SiM Racing</MenuHeader>
			{/* <div>
				<Link sx={dataLinkSx} to='/data/articles'>
					Articles
				</Link>
			</div> */}
			<div>
				<Link sx={dataLinkSx} to='/data/cars'>
					Cars
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/drivers'>
					Drivers
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/locations'>
					Locations
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/races'>
					Races
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/tools/tournaments/two-heat-qualifier'>
					2 Heat Qualifier Championship
				</Link>
			</div>
			{/* <div>
				<Link sx={dataLinkSx} to='/data/vote-contests'>
					Vote Contests
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/candidates'>
					Vote Contest Candidates
				</Link>
			</div> */}
			{/* <div>
				<Link sx={dataLinkSx} to='/data/dress-a-like-entries'>
					Dress-A-Like Entries
				</Link>
			</div> */}

			{/* <hr />
			<div>
				<Link sx={dataLinkSx} to='/data/player-stats'>
					Player / Driver Stats
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/prize-draws'>
					Grand Prize Draws
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/jackpot-results'>
					Jackpot Results
				</Link>
			</div> */}

			<MenuHeader theme={theme}>Grateful Hearts</MenuHeader>
			<div>
				<Link sx={dataLinkSx} to='/data/brands'>
					Brands
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/charities'>
					Charities
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/sponsors'>
					Sponsors
				</Link>
			</div>
			{/* <div>
				<Link sx={dataLinkSx} to='/data/donation-matrixes'>
					Donation Matrixes
				</Link>
			</div> */}
			<div>
				<Link sx={dataLinkSx} to='/data/raffle-prizes'>
					Raffle Prizes
				</Link>
			</div>
			<hr />
			<div>
				<Link sx={dataLinkSx} to='/data/users'>
					Users
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/raffle-tickets'>
					Raffle Tickets
				</Link>
			</div>
			<div>
				<Link sx={dataLinkSx} to='/data/jackpot-tickets'>
					Jackpot Tickets
				</Link>
			</div>
			<hr />
			<div>
				<Link sx={dataLinkSx} to='/data/admins'>
					Admins
				</Link>
			</div>
		</MenuContainer>
	);
};

export default AppMenu;
