import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner } from 'theme-ui';
import useCars from '../../../hooks/useCars';
import useDrivers from '../../../hooks/useDrivers';
import useSponsors from '../../../hooks/useSponsors';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import SelectOneList from '../../segments/SelectOneList';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const CarsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveCar,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useCars();
	const {
		getDrivers,
		drivers,
		error: driverError,
		isProcessing: isProcessingDrivers
	} = useDrivers();
	const {
		getSponsors,
		sponsors,
		error: sponsorError,
		isProcessing: isProcessingEntities
	} = useSponsors();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [hasRequestedDrivers, setHasRequestedDrivers] = useState(false);
	const [hasRequestedSponsors, setHasRequestedSponsors] = useState(false);

	useEffect(() => {
		if (!hasRequestedDrivers) {
			setHasRequestedDrivers(true);
			getDrivers();
		}
	}, [hasRequestedDrivers, getDrivers, setHasRequestedDrivers]);

	useEffect(() => {
		if (!hasRequestedSponsors) {
			setHasRequestedSponsors(true);
			getSponsors();
		}
	}, [hasRequestedSponsors, getSponsors, setHasRequestedSponsors]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveCar(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Car:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='number'>Car Number</Label>
							<Input
								name='number'
								mb={3}
								value={localData?.number || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='driver'>Driver</Label>
							<SelectOne
								data={drivers}
								value={localData?.driver}
								name='driver'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No drivers found to choose from.'
							/>

							<Label htmlFor='entity'>Sponsors</Label>
							<SelectOneList
								data={sponsors}
								value={localData?.entities}
								name='entities'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No sponsors found to choose from.'
							/>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Car'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Car Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default CarsAdd;
