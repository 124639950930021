/**
 * App-wide Actions
 */

export const QUEUE_DEFERRED_ACTION = 'QUEUE_DEFERRED_ACTION';

/**
 * Admins Actions
 */

export const ADMINS_ADD_ERROR = 'ADMINS_ADD_ERROR';
export const ADMINS_UPDATE_ERROR = 'ADMINS_UPDATE_ERROR';
export const ADMINS_LOADED = 'ADMINS_LOADED';
export const ADMINS_LOAD_ERROR = 'ADMINS_LOAD_ERROR';
export const ADMINS_REQUEST_END = 'ADMINS_REQUEST_END';
export const ADMINS_REQUEST_START = 'ADMINS_REQUEST_START';
export const ADMINS_ADD_LOCAL_RECORD = 'ADMINS_ADD_LOCAL_RECORD';
export const ADMINS_UPDATE_LOCAL_RECORD = 'ADMINS_UPDATE_LOCAL_RECORD';
export const ADMIN_DELETED = 'ADMIN_DELETED';
export const ADMIN_DELETE_ERROR = 'ADMIN_DELETE_ERROR';
export const ADMIN_DELETE_RESULT_CLEAR = 'ADMIN_DELETE_RESULT_CLEAR';
export const ADMINS_DROP_LOCAL_RECORD = 'ADMINS_DROP_LOCAL_RECORD';
export const ADMIN_SAVED = 'ADMIN_SAVED';
export const ADMIN_SAVE_ERROR = 'ADMIN_SAVE_ERROR';
export const ADMIN_SAVE_RESULT_CLEAR = 'ADMIN_SAVE_RESULT_CLEAR';
export const SET_SELECTED_ADMIN_ID = 'SET_SELECTED_ADMIN_ID';

/**
 * Articles Actions
 */

export const ADD_NEW_ARTICLE = 'ADD_NEW_ARTICLE';
export const ARTICLES_ADD_ERROR = 'ARTICLES_ADD_ERROR';
export const ARTICLES_LOADED = 'ARTICLES_LOADED';
export const ARTICLES_LOAD_ERROR = 'ARTICLES_LOAD_ERROR';
export const ARTICLES_REQUEST_START = 'ARTICLES_REQUEST_START';
export const ARTICLES_REQUEST_END = 'ARTICLES_REQUEST_END';
export const ARTICLES_UPDATE_ERROR = 'ARTICLES_UPDATE_ERROR';
export const ARTICLES_ADD_LOCAL_RECORD = 'ARTICLES_ADD_LOCAL_RECORD';
export const ARTICLES_UPDATE_LOCAL_RECORD = 'ARTICLES_UPDATE_LOCAL_RECORD';
export const ARTICLES_DROP_LOCAL_RECORD = 'ARTICLES_DROP_LOCAL_RECORD';
export const ARTICLE_DELETED = 'ARTICLE_DELETED';
export const ARTICLE_DELETE_ERROR = 'ARTICLE_DELETE_ERROR';
export const ARTICLE_DELETE_RESULT_CLEAR = 'ARTICLE_DELETE_RESULT_CLEAR';
export const ARTICLE_SAVED = 'ARTICLE_SAVED';
export const ARTICLE_SAVE_ERROR = 'ARTICLE_SAVE_ERROR';
export const ARTICLE_SAVE_RESULT_CLEAR = 'ARTICLE_SAVE_RESULT_CLEAR';
export const SET_SELECTED_ARTICLE_ID = 'SET_SELECTED_ARTICLE_ID';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';

/**
 * Brands Actions
 */

export const ADD_NEW_BRAND = 'ADD_NEW_BRAND';
export const BRANDS_ADD_ERROR = 'BRANDS_ADD_ERROR';
export const BRANDS_LOADED = 'BRANDS_LOADED';
export const BRANDS_LOAD_ERROR = 'BRANDS_LOAD_ERROR';
export const BRANDS_REQUEST_START = 'BRANDS_REQUEST_START';
export const BRANDS_REQUEST_END = 'BRANDS_REQUEST_END';
export const BRANDS_UPDATE_ERROR = 'BRANDS_UPDATE_ERROR';
export const BRANDS_ADD_LOCAL_RECORD = 'BRANDS_ADD_LOCAL_RECORD';
export const BRANDS_UPDATE_LOCAL_RECORD = 'BRANDS_UPDATE_LOCAL_RECORD';
export const BRANDS_DROP_LOCAL_RECORD = 'BRANDS_DROP_LOCAL_RECORD';
export const BRAND_DELETED = 'BRAND_DELETED';
export const BRAND_DELETE_ERROR = 'BRAND_DELETE_ERROR';
export const BRAND_DELETE_RESULT_CLEAR = 'BRAND_DELETE_RESULT_CLEAR';
export const BRAND_SAVED = 'BRAND_SAVED';
export const BRAND_SAVE_ERROR = 'BRAND_SAVE_ERROR';
export const BRAND_SAVE_RESULT_CLEAR = 'BRAND_SAVE_RESULT_CLEAR';
export const SET_SELECTED_BRAND_ID = 'SET_SELECTED_BRAND_ID';
export const UPDATE_BRAND = 'UPDATE_BRAND';

/**
 * Candidates Actions
 */

export const ADD_NEW_CANDIDATE = 'ADD_NEW_CANDIDATE';
export const CANDIDATES_ADD_ERROR = 'CANDIDATES_ADD_ERROR';
export const CANDIDATES_LOADED = 'CANDIDATES_LOADED';
export const CANDIDATES_LOAD_ERROR = 'CANDIDATES_LOAD_ERROR';
export const CANDIDATES_REQUEST_START = 'CANDIDATES_REQUEST_START';
export const CANDIDATES_REQUEST_END = 'CANDIDATES_REQUEST_END';
export const CANDIDATES_UPDATE_ERROR = 'CANDIDATES_UPDATE_ERROR';
export const CANDIDATES_ADD_LOCAL_RECORD = 'CANDIDATES_ADD_LOCAL_RECORD';
export const CANDIDATES_UPDATE_LOCAL_RECORD = 'CANDIDATES_UPDATE_LOCAL_RECORD';
export const CANDIDATES_DROP_LOCAL_RECORD = 'CANDIDATES_DROP_LOCAL_RECORD';
export const CANDIDATE_DELETED = 'CANDIDATE_DELETED';
export const CANDIDATE_DELETE_ERROR = 'CANDIDATE_DELETE_ERROR';
export const CANDIDATE_DELETE_RESULT_CLEAR = 'CANDIDATE_DELETE_RESULT_CLEAR';
export const CANDIDATE_SAVED = 'CANDIDATE_SAVED';
export const CANDIDATE_SAVE_ERROR = 'CANDIDATE_SAVE_ERROR';
export const CANDIDATE_SAVE_RESULT_CLEAR = 'CANDIDATE_SAVE_RESULT_CLEAR';
export const SET_SELECTED_CANDIDATE_ID = 'SET_SELECTED_CANDIDATE_ID';
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE';

/**
 * Cars Actions
 */

export const ADD_NEW_CAR = 'ADD_NEW_CAR';
export const CARS_ADD_ERROR = 'CARS_ADD_ERROR';
export const CARS_LOADED = 'CARS_LOADED';
export const CARS_LOAD_ERROR = 'CARS_LOAD_ERROR';
export const CARS_REQUEST_START = 'CARS_REQUEST_START';
export const CARS_REQUEST_END = 'CARS_REQUEST_END';
export const CARS_UPDATE_ERROR = 'CARS_UPDATE_ERROR';
export const CARS_ADD_LOCAL_RECORD = 'CARS_ADD_LOCAL_RECORD';
export const CARS_UPDATE_LOCAL_RECORD = 'CARS_UPDATE_LOCAL_RECORD';
export const CARS_DROP_LOCAL_RECORD = 'CARS_DROP_LOCAL_RECORD';
export const CAR_DELETED = 'CAR_DELETED';
export const CAR_DELETE_ERROR = 'CAR_DELETE_ERROR';
export const CAR_DELETE_RESULT_CLEAR = 'CAR_DELETE_RESULT_CLEAR';
export const CAR_SAVED = 'CAR_SAVED';
export const CAR_SAVE_ERROR = 'CAR_SAVE_ERROR';
export const CAR_SAVE_RESULT_CLEAR = 'CAR_SAVE_RESULT_CLEAR';
export const SET_SELECTED_CAR_ID = 'SET_SELECTED_CAR_ID';
export const UPDATE_CAR = 'UPDATE_CAR';

/**
 * Charities Actions
 */

export const ADD_NEW_CHARITY = 'ADD_NEW_CHARITY';
export const CHARITIES_ADD_ERROR = 'CHARITIES_ADD_ERROR';
export const CHARITIES_LOADED = 'CHARITIES_LOADED';
export const CHARITIES_LOAD_ERROR = 'CHARITIES_LOAD_ERROR';
export const CHARITIES_REQUEST_START = 'CHARITIES_REQUEST_START';
export const CHARITIES_REQUEST_END = 'CHARITIES_REQUEST_END';
export const CHARITIES_UPDATE_ERROR = 'CHARITIES_UPDATE_ERROR';
export const CHARITIES_ADD_LOCAL_RECORD = 'CHARITIES_ADD_LOCAL_RECORD';
export const CHARITIES_UPDATE_LOCAL_RECORD = 'CHARITIES_UPDATE_LOCAL_RECORD';
export const CHARITIES_DROP_LOCAL_RECORD = 'CHARITIES_DROP_LOCAL_RECORD';
export const CHARITY_DELETED = 'CHARITY_DELETED';
export const CHARITY_DELETE_ERROR = 'CHARITY_DELETE_ERROR';
export const CHARITY_DELETE_RESULT_CLEAR = 'CHARITY_DELETE_RESULT_CLEAR';
export const CHARITY_SAVED = 'CHARITY_SAVED';
export const CHARITY_SAVE_ERROR = 'CHARITY_SAVE_ERROR';
export const CHARITY_SAVE_RESULT_CLEAR = 'CHARITY_SAVE_RESULT_CLEAR';
export const SET_SELECTED_CHARITY_ID = 'SET_SELECTED_CHARITY_ID';
export const UPDATE_CHARITY = 'UPDATE_CHARITY';

/**
 * Communication Hub Actions
 */

export const COMMS_ADD_ERROR = 'COMMS_ADD_ERROR';
export const COMMS_LOADED = 'COMMS_LOADED';
export const COMMS_LOAD_ERROR = 'COMMS_LOAD_ERROR';
export const COMMS_REQUEST_END = 'COMMS_REQUEST_END';
export const COMMS_REQUEST_START = 'COMMS_REQUEST_START';
export const COMMS_UPDATE_ERROR = 'COMMS_UPDATE_ERROR';
export const COMMS_ADD_LOCAL_RECORD = 'COMMS_ADD_LOCAL_RECORD';
export const COMMS_UPDATE_LOCAL_RECORD = 'COMMS_UPDATE_LOCAL_RECORD';
export const COMMS_DROP_LOCAL_RECORD = 'COMMS_DROP_LOCAL_RECORD';
export const EMAIL_TEMPLATE_DELETED = 'EMAIL_TEMPLATE_DELETED';
export const EMAIL_TEMPLATE_DELETE_ERROR = 'EMAIL_TEMPLATE_DELETE_ERROR';
export const EMAIL_TEMPLATE_DELETE_RESULT_CLEAR = 'EMAIL_TEMPLATE_DELETE_RESULT_CLEAR';
export const EMAIL_TEMPLATE_SAVED = 'EMAIL_TEMPLATE_SAVED';
export const EMAIL_TEMPLATE_SAVE_ERROR = 'EMAIL_TEMPLATE_SAVE_ERROR';
export const EMAIL_TEMPLATE_SAVE_RESULT_CLEAR = 'EMAIL_TEMPLATE_SAVE_RESULT_CLEAR';
export const SET_SELECTED_EMAIL_TEMPLATE_ID = 'SET_SELECTED_EMAIL_TEMPLATE_ID';
export const SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE = 'SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE';
export const SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR = 'SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR';

/**
 * Donation History Actions
 */

export const DONATION_HISTORY_LOADED = 'DONATION_HISTORY_LOADED';
export const DONATION_HISTORY_LOAD_ERROR = 'DONATION_HISTORY_LOAD_ERROR';
export const DONATION_HISTORY_REQUEST_START = 'DONATION_HISTORY_REQUEST_START';
export const DONATION_HISTORY_REQUEST_END = 'DONATION_HISTORY_REQUEST_END';

/**
 * Donation Matrixes Actions
 */

export const ADD_NEW_DONATIONMATRIX = 'ADD_NEW_DONATIONMATRIX';
export const DONATIONMATRIXES_ADD_ERROR = 'DONATIONMATRIXES_ADD_ERROR';
export const DONATIONMATRIXES_LOADED = 'DONATIONMATRIXES_LOADED';
export const DONATIONMATRIXES_LOAD_ERROR = 'DONATIONMATRIXES_LOAD_ERROR';
export const DONATIONMATRIXES_REQUEST_START = 'DONATIONMATRIXES_REQUEST_START';
export const DONATIONMATRIXES_REQUEST_END = 'DONATIONMATRIXES_REQUEST_END';
export const DONATIONMATRIXES_UPDATE_ERROR = 'DONATIONMATRIXES_UPDATE_ERROR';
export const DONATIONMATRIXES_ADD_LOCAL_RECORD = 'DONATIONMATRIXES_ADD_LOCAL_RECORD';
export const DONATIONMATRIXES_UPDATE_LOCAL_RECORD = 'DONATIONMATRIXES_UPDATE_LOCAL_RECORD';
export const DONATIONMATRIXES_DROP_LOCAL_RECORD = 'DONATIONMATRIXES_DROP_LOCAL_RECORD';
export const DONATIONMATRIX_DELETED = 'DONATIONMATRIX_DELETED';
export const DONATIONMATRIX_DELETE_ERROR = 'DONATIONMATRIX_DELETE_ERROR';
export const DONATIONMATRIX_DELETE_RESULT_CLEAR = 'DONATIONMATRIX_DELETE_RESULT_CLEAR';
export const DONATIONMATRIX_SAVED = 'DONATIONMATRIX_SAVED';
export const DONATIONMATRIX_SAVE_ERROR = 'DONATIONMATRIX_SAVE_ERROR';
export const DONATIONMATRIX_SAVE_RESULT_CLEAR = 'DONATIONMATRIX_SAVE_RESULT_CLEAR';
export const SET_SELECTED_DONATIONMATRIX_ID = 'SET_SELECTED_DONATIONMATRIX_ID';
export const UPDATE_DONATIONMATRIX = 'UPDATE_DONATIONMATRIX';

/**
 * Drivers Actions
 */

export const ADD_NEW_DRIVER = 'ADD_NEW_DRIVER';
export const DRIVERS_ADD_ERROR = 'DRIVERS_ADD_ERROR';
export const DRIVERS_LOADED = 'DRIVERS_LOADED';
export const DRIVERS_LOAD_ERROR = 'DRIVERS_LOAD_ERROR';
export const DRIVERS_REQUEST_START = 'DRIVERS_REQUEST_START';
export const DRIVERS_REQUEST_END = 'DRIVERS_REQUEST_END';
export const DRIVERS_UPDATE_ERROR = 'DRIVERS_UPDATE_ERROR';
export const DRIVERS_ADD_LOCAL_RECORD = 'DRIVERS_ADD_LOCAL_RECORD';
export const DRIVERS_UPDATE_LOCAL_RECORD = 'DRIVERS_UPDATE_LOCAL_RECORD';
export const DRIVERS_DROP_LOCAL_RECORD = 'DRIVERS_DROP_LOCAL_RECORD';
export const DRIVER_DELETED = 'DRIVER_DELETED';
export const DRIVER_DELETE_ERROR = 'DRIVER_DELETE_ERROR';
export const DRIVER_DELETE_RESULT_CLEAR = 'DRIVER_DELETE_RESULT_CLEAR';
export const DRIVER_SAVED = 'DRIVER_SAVED';
export const DRIVER_SAVE_ERROR = 'DRIVER_SAVE_ERROR';
export const DRIVER_SAVE_RESULT_CLEAR = 'DRIVER_SAVE_RESULT_CLEAR';
export const SET_SELECTED_DRIVER_ID = 'SET_SELECTED_DRIVER_ID';
export const UPDATE_DRIVER = 'UPDATE_DRIVER';

/**
 * Events Actions
 */

export const ADD_NEW_EVENT = 'ADD_NEW_EVENT';
export const EVENTS_ADD_ERROR = 'EVENTS_ADD_ERROR';
export const EVENTS_LOADED = 'EVENTS_LOADED';
export const EVENTS_LOAD_ERROR = 'EVENTS_LOAD_ERROR';
export const EVENTS_REQUEST_START = 'EVENTS_REQUEST_START';
export const EVENTS_REQUEST_END = 'EVENTS_REQUEST_END';
export const EVENTS_UPDATE_ERROR = 'EVENTS_UPDATE_ERROR';
export const EVENTS_ADD_LOCAL_RECORD = 'EVENTS_ADD_LOCAL_RECORD';
export const EVENTS_UPDATE_LOCAL_RECORD = 'EVENTS_UPDATE_LOCAL_RECORD';
export const EVENTS_DROP_LOCAL_RECORD = 'EVENTS_DROP_LOCAL_RECORD';
export const EVENT_DELETED = 'EVENT_DELETED';
export const EVENT_DELETE_ERROR = 'EVENT_DELETE_ERROR';
export const EVENT_DELETE_RESULT_CLEAR = 'EVENT_DELETE_RESULT_CLEAR';
export const EVENT_SAVED = 'EVENT_SAVED';
export const EVENT_SAVE_ERROR = 'EVENT_SAVE_ERROR';
export const EVENT_SAVE_RESULT_CLEAR = 'EVENT_SAVE_RESULT_CLEAR';
export const SET_SELECTED_EVENT_ID = 'SET_SELECTED_EVENT_ID';
export const UPDATE_EVENT = 'UPDATE_EVENT';

/**
 * Jackpot Picks Actions
 */

export const JACKPOT_PICKS_LOADED = 'JACKPOT_PICKS_LOADED';
export const JACKPOT_PICKS_LOAD_ERROR = 'JACKPOT_PICKS_LOAD_ERROR';
export const JACKPOT_PICKS_CREDITS_LOADED = 'JACKPOT_PICKS_CREDITS_LOADED';
export const JACKPOT_PICKS_CREDITS_LOAD_ERROR = 'JACKPOT_PICKS_CREDITS_LOAD_ERROR';
export const JACKPOT_PICKS_REQUEST_START = 'JACKPOT_PICKS_REQUEST_START';
export const JACKPOT_PICKS_REQUEST_END = 'JACKPOT_PICKS_REQUEST_END';

/**
 * Jackpot Results Actions
 */

export const ADD_NEW_JACKPOTRESULT = 'ADD_NEW_JACKPOTRESULT';
export const JACKPOTRESULTS_ADD_ERROR = 'JACKPOTRESULTS_ADD_ERROR';
export const JACKPOTRESULTS_LOADED = 'JACKPOTRESULTS_LOADED';
export const JACKPOTRESULTS_LOAD_ERROR = 'JACKPOTRESULTS_LOAD_ERROR';
export const JACKPOTRESULTS_REQUEST_START = 'JACKPOTRESULTS_REQUEST_START';
export const JACKPOTRESULTS_REQUEST_END = 'JACKPOTRESULTS_REQUEST_END';
export const JACKPOTRESULTS_UPDATE_ERROR = 'JACKPOTRESULTS_UPDATE_ERROR';
export const JACKPOTRESULTS_ADD_LOCAL_RECORD = 'JACKPOTRESULTS_ADD_LOCAL_RECORD';
export const JACKPOTRESULTS_UPDATE_LOCAL_RECORD = 'JACKPOTRESULTS_UPDATE_LOCAL_RECORD';
export const JACKPOTRESULTS_DROP_LOCAL_RECORD = 'JACKPOTRESULTS_DROP_LOCAL_RECORD';
export const JACKPOTRESULT_DELETED = 'JACKPOTRESULT_DELETED';
export const JACKPOTRESULT_DELETE_ERROR = 'JACKPOTRESULT_DELETE_ERROR';
export const JACKPOTRESULT_DELETE_RESULT_CLEAR = 'JACKPOTRESULT_DELETE_RESULT_CLEAR';
export const JACKPOTRESULT_SAVED = 'JACKPOTRESULT_SAVED';
export const JACKPOTRESULT_SAVE_ERROR = 'JACKPOTRESULT_SAVE_ERROR';
export const JACKPOTRESULT_SAVE_RESULT_CLEAR = 'JACKPOTRESULT_SAVE_RESULT_CLEAR';
export const SET_SELECTED_JACKPOTRESULT_ID = 'SET_SELECTED_JACKPOTRESULT_ID';
export const UPDATE_JACKPOTRESULT = 'UPDATE_JACKPOTRESULT';

/**
 * Jackpot UI Actions
 */

export const JACKPOT_UI_HIDE_DETAIL_VIEW = 'JACKPOT_UI_HIDE_DETAIL_VIEW';
export const JACKPOT_UI_SHOW_DETAIL_VIEW = 'JACKPOT_UI_SHOW_DETAIL_VIEW';
export const JACKPOT_UI_SET_CURRENT_CARD = 'JACKPOT_UI_SET_CURRENT_CARD';
export const JACKPOT_UI_CLEAR_CURRENT_CARD = 'JACKPOT_UI_CLEAR_CURRENT_CARD';

/**
 * Locations Actions
 */

export const ADD_NEW_LOCATION = 'ADD_NEW_LOCATION';
export const LOCATIONS_ADD_ERROR = 'LOCATIONS_ADD_ERROR';
export const LOCATIONS_LOADED = 'LOCATIONS_LOADED';
export const LOCATIONS_LOAD_ERROR = 'LOCATIONS_LOAD_ERROR';
export const LOCATIONS_REQUEST_START = 'LOCATIONS_REQUEST_START';
export const LOCATIONS_REQUEST_END = 'LOCATIONS_REQUEST_END';
export const LOCATIONS_UPDATE_ERROR = 'LOCATIONS_UPDATE_ERROR';
export const LOCATIONS_ADD_LOCAL_RECORD = 'LOCATIONS_ADD_LOCAL_RECORD';
export const LOCATIONS_UPDATE_LOCAL_RECORD = 'LOCATIONS_UPDATE_LOCAL_RECORD';
export const LOCATIONS_DROP_LOCAL_RECORD = 'LOCATIONS_DROP_LOCAL_RECORD';
export const LOCATION_DELETED = 'LOCATION_DELETED';
export const LOCATION_DELETE_ERROR = 'LOCATION_DELETE_ERROR';
export const LOCATION_DELETE_RESULT_CLEAR = 'LOCATION_DELETE_RESULT_CLEAR';
export const LOCATION_SAVED = 'LOCATION_SAVED';
export const LOCATION_SAVE_ERROR = 'LOCATION_SAVE_ERROR';
export const LOCATION_SAVE_RESULT_CLEAR = 'LOCATION_SAVE_RESULT_CLEAR';
export const SET_SELECTED_LOCATION_ID = 'SET_SELECTED_LOCATION_ID';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';

/**
 * Prize Draws Actions
 */

export const ADD_NEW_PRIZEDRAW = 'ADD_NEW_PRIZEDRAW';
export const PRIZEDRAWS_ADD_ERROR = 'PRIZEDRAWS_ADD_ERROR';
export const PRIZEDRAWS_LOADED = 'PRIZEDRAWS_LOADED';
export const PRIZEDRAWS_LOAD_ERROR = 'PRIZEDRAWS_LOAD_ERROR';
export const PRIZEDRAWS_REQUEST_START = 'PRIZEDRAWS_REQUEST_START';
export const PRIZEDRAWS_REQUEST_END = 'PRIZEDRAWS_REQUEST_END';
export const PRIZEDRAWS_UPDATE_ERROR = 'PRIZEDRAWS_UPDATE_ERROR';
export const PRIZEDRAWS_ADD_LOCAL_RECORD = 'PRIZEDRAWS_ADD_LOCAL_RECORD';
export const PRIZEDRAWS_UPDATE_LOCAL_RECORD = 'PRIZEDRAWS_UPDATE_LOCAL_RECORD';
export const PRIZEDRAWS_DROP_LOCAL_RECORD = 'PRIZEDRAWS_DROP_LOCAL_RECORD';
export const PRIZEDRAW_DELETED = 'PRIZEDRAW_DELETED';
export const PRIZEDRAW_DELETE_ERROR = 'PRIZEDRAW_DELETE_ERROR';
export const PRIZEDRAW_DELETE_RESULT_CLEAR = 'PRIZEDRAW_DELETE_RESULT_CLEAR';
export const PRIZEDRAW_SAVED = 'PRIZEDRAW_SAVED';
export const PRIZEDRAW_SAVE_ERROR = 'PRIZEDRAW_SAVE_ERROR';
export const PRIZEDRAW_SAVE_RESULT_CLEAR = 'PRIZEDRAW_SAVE_RESULT_CLEAR';
export const SET_SELECTED_PRIZEDRAW_ID = 'SET_SELECTED_PRIZEDRAW_ID';
export const UPDATE_PRIZEDRAW = 'UPDATE_PRIZEDRAW';

/**
 * Products Actions
 */

export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT';
export const PRODUCTS_ADD_ERROR = 'PRODUCTS_ADD_ERROR';
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCTS_LOAD_ERROR = 'PRODUCTS_LOAD_ERROR';
export const PRODUCTS_REQUEST_START = 'PRODUCTS_REQUEST_START';
export const PRODUCTS_REQUEST_END = 'PRODUCTS_REQUEST_END';
export const PRODUCTS_UPDATE_ERROR = 'PRODUCTS_UPDATE_ERROR';
export const PRODUCTS_ADD_LOCAL_RECORD = 'PRODUCTS_ADD_LOCAL_RECORD';
export const PRODUCTS_UPDATE_LOCAL_RECORD = 'PRODUCTS_UPDATE_LOCAL_RECORD';
export const PRODUCTS_DROP_LOCAL_RECORD = 'PRODUCTS_DROP_LOCAL_RECORD';
export const PRODUCT_DELETED = 'PRODUCT_DELETED';
export const PRODUCT_DELETE_ERROR = 'PRODUCT_DELETE_ERROR';
export const PRODUCT_DELETE_RESULT_CLEAR = 'PRODUCT_DELETE_RESULT_CLEAR';
export const PRODUCT_SAVED = 'PRODUCT_SAVED';
export const PRODUCT_SAVE_ERROR = 'PRODUCT_SAVE_ERROR';
export const PRODUCT_SAVE_RESULT_CLEAR = 'PRODUCT_SAVE_RESULT_CLEAR';
export const SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

/**
 * Races Actions
 */

export const ADD_NEW_RACE = 'ADD_NEW_RACE';
export const RACES_ADD_ERROR = 'RACES_ADD_ERROR';
export const RACES_LOADED = 'RACES_LOADED';
export const RACES_LOAD_ERROR = 'RACES_LOAD_ERROR';
export const RACES_REQUEST_START = 'RACES_REQUEST_START';
export const RACES_REQUEST_END = 'RACES_REQUEST_END';
export const RACES_UPDATE_ERROR = 'RACES_UPDATE_ERROR';
export const RACES_ADD_LOCAL_RECORD = 'RACES_ADD_LOCAL_RECORD';
export const RACES_UPDATE_LOCAL_RECORD = 'RACES_UPDATE_LOCAL_RECORD';
export const RACES_DROP_LOCAL_RECORD = 'RACES_DROP_LOCAL_RECORD';
export const RACE_DELETED = 'RACE_DELETED';
export const RACE_DELETE_ERROR = 'RACE_DELETE_ERROR';
export const RACE_DELETE_RESULT_CLEAR = 'RACE_DELETE_RESULT_CLEAR';
export const RACE_SAVED = 'RACE_SAVED';
export const RACE_SAVE_ERROR = 'RACE_SAVE_ERROR';
export const RACE_SAVE_RESULT_CLEAR = 'RACE_SAVE_RESULT_CLEAR';
export const SET_SELECTED_RACE_ID = 'SET_SELECTED_RACE_ID';
export const UPDATE_RACE = 'UPDATE_RACE';

/**
 * Race Results Actions
 */

export const ADD_NEW_RACERESULT = 'ADD_NEW_RACERESULT';
export const RACERESULTS_ADD_ERROR = 'RACERESULTS_ADD_ERROR';
export const RACERESULTS_LOADED = 'RACERESULTS_LOADED';
export const RACERESULTS_LOAD_ERROR = 'RACERESULTS_LOAD_ERROR';
export const RACERESULTS_REQUEST_START = 'RACERESULTS_REQUEST_START';
export const RACERESULTS_REQUEST_END = 'RACERESULTS_REQUEST_END';
export const RACERESULTS_UPDATE_ERROR = 'RACERESULTS_UPDATE_ERROR';
export const RACERESULTS_ADD_LOCAL_RECORD = 'RACERESULTS_ADD_LOCAL_RECORD';
export const RACERESULTS_UPDATE_LOCAL_RECORD = 'RACERESULTS_UPDATE_LOCAL_RECORD';
export const RACERESULTS_DROP_LOCAL_RECORD = 'RACERESULTS_DROP_LOCAL_RECORD';
export const RACERESULT_DELETED = 'RACERESULT_DELETED';
export const RACERESULT_DELETE_ERROR = 'RACERESULT_DELETE_ERROR';
export const RACERESULT_DELETE_RESULT_CLEAR = 'RACERESULT_DELETE_RESULT_CLEAR';
export const RACERESULT_SAVED = 'RACERESULT_SAVED';
export const RACERESULT_SAVE_ERROR = 'RACERESULT_SAVE_ERROR';
export const RACERESULT_SAVE_RESULT_CLEAR = 'RACERESULT_SAVE_RESULT_CLEAR';
export const SET_SELECTED_RACERESULT_ID = 'SET_SELECTED_RACERESULT_ID';
export const UPDATE_RACERESULT = 'UPDATE_RACERESULT';

/**
 * Raffle Prizes Actions
 */

export const ADD_NEW_RAFFLEPRIZE = 'ADD_NEW_RAFFLEPRIZE';
export const RAFFLEPRIZES_ADD_ERROR = 'RAFFLEPRIZES_ADD_ERROR';
export const RAFFLEPRIZES_LOADED = 'RAFFLEPRIZES_LOADED';
export const RAFFLEPRIZES_LOAD_ERROR = 'RAFFLEPRIZES_LOAD_ERROR';
export const RAFFLEPRIZES_REQUEST_START = 'RAFFLEPRIZES_REQUEST_START';
export const RAFFLEPRIZES_REQUEST_END = 'RAFFLEPRIZES_REQUEST_END';
export const RAFFLEPRIZES_UPDATE_ERROR = 'RAFFLEPRIZES_UPDATE_ERROR';
export const RAFFLEPRIZES_ADD_LOCAL_RECORD = 'RAFFLEPRIZES_ADD_LOCAL_RECORD';
export const RAFFLEPRIZES_UPDATE_LOCAL_RECORD = 'RAFFLEPRIZES_UPDATE_LOCAL_RECORD';
export const RAFFLEPRIZES_DROP_LOCAL_RECORD = 'RAFFLEPRIZES_DROP_LOCAL_RECORD';
export const RAFFLEPRIZE_DELETED = 'RAFFLEPRIZE_DELETED';
export const RAFFLEPRIZE_DELETE_ERROR = 'RAFFLEPRIZE_DELETE_ERROR';
export const RAFFLEPRIZE_DELETE_RESULT_CLEAR = 'RAFFLEPRIZE_DELETE_RESULT_CLEAR';
export const RAFFLEPRIZE_SAVED = 'RAFFLEPRIZE_SAVED';
export const RAFFLEPRIZE_SAVE_ERROR = 'RAFFLEPRIZE_SAVE_ERROR';
export const RAFFLEPRIZE_SAVE_RESULT_CLEAR = 'RAFFLEPRIZE_SAVE_RESULT_CLEAR';
export const SET_SELECTED_RAFFLEPRIZE_ID = 'SET_SELECTED_RAFFLEPRIZE_ID';
export const UPDATE_RAFFLEPRIZE = 'UPDATE_RAFFLEPRIZE';

/**
 * Raffle Ticket Actions
 */

export const RAFFLE_TICKETS_LOADED = 'RAFFLE_TICKETS_LOADED';
export const RAFFLE_TICKETS_LOAD_ERROR = 'RAFFLE_TICKETS_LOAD_ERROR';
export const RAFFLE_TICKETS_REQUEST_START = 'RAFFLE_TICKETS_REQUEST_START';
export const RAFFLE_TICKETS_REQUEST_END = 'RAFFLE_TICKETS_REQUEST_END';

/**
 * Sponsors Actions
 */

export const ADD_NEW_SPONSOR = 'ADD_NEW_SPONSOR';
export const SPONSORS_ADD_ERROR = 'SPONSORS_ADD_ERROR';
export const SPONSORS_LOADED = 'SPONSORS_LOADED';
export const SPONSORS_LOAD_ERROR = 'SPONSORS_LOAD_ERROR';
export const SPONSORS_REQUEST_START = 'SPONSORS_REQUEST_START';
export const SPONSORS_REQUEST_END = 'SPONSORS_REQUEST_END';
export const SPONSORS_UPDATE_ERROR = 'SPONSORS_UPDATE_ERROR';
export const SPONSORS_ADD_LOCAL_RECORD = 'SPONSORS_ADD_LOCAL_RECORD';
export const SPONSORS_UPDATE_LOCAL_RECORD = 'SPONSORS_UPDATE_LOCAL_RECORD';
export const SPONSORS_DROP_LOCAL_RECORD = 'SPONSORS_DROP_LOCAL_RECORD';
export const SPONSOR_DELETED = 'SPONSOR_DELETED';
export const SPONSOR_DELETE_ERROR = 'SPONSOR_DELETE_ERROR';
export const SPONSOR_DELETE_RESULT_CLEAR = 'SPONSOR_DELETE_RESULT_CLEAR';
export const SPONSOR_SAVED = 'SPONSOR_SAVED';
export const SPONSOR_SAVE_ERROR = 'SPONSOR_SAVE_ERROR';
export const SPONSOR_SAVE_RESULT_CLEAR = 'SPONSOR_SAVE_RESULT_CLEAR';
export const SET_SELECTED_SPONSOR_ID = 'SET_SELECTED_SPONSOR_ID';
export const UPDATE_SPONSOR = 'UPDATE_SPONSOR';

/**
 * Tournaments Actions
 */

export const ADD_NEW_TOURNAMENT = 'ADD_NEW_TOURNAMENT';
export const TOURNAMENTS_ADD_ERROR = 'TOURNAMENTS_ADD_ERROR';
export const TOURNAMENTS_LOADED = 'TOURNAMENTS_LOADED';
export const TOURNAMENTS_LOAD_ERROR = 'TOURNAMENTS_LOAD_ERROR';
export const TOURNAMENTS_REQUEST_START = 'TOURNAMENTS_REQUEST_START';
export const TOURNAMENTS_REQUEST_END = 'TOURNAMENTS_REQUEST_END';
export const TOURNAMENTS_UPDATE_ERROR = 'TOURNAMENTS_UPDATE_ERROR';
export const TOURNAMENTS_ADD_LOCAL_RECORD = 'TOURNAMENTS_ADD_LOCAL_RECORD';
export const TOURNAMENTS_UPDATE_LOCAL_RECORD = 'TOURNAMENTS_UPDATE_LOCAL_RECORD';
export const TOURNAMENTS_DROP_LOCAL_RECORD = 'TOURNAMENTS_DROP_LOCAL_RECORD';
export const TOURNAMENT_DELETED = 'TOURNAMENT_DELETED';
export const TOURNAMENT_DELETE_ERROR = 'TOURNAMENT_DELETE_ERROR';
export const TOURNAMENT_DELETE_RESULT_CLEAR = 'TOURNAMENT_DELETE_RESULT_CLEAR';
export const TOURNAMENT_SAVED = 'TOURNAMENT_SAVED';
export const TOURNAMENT_SAVE_ERROR = 'TOURNAMENT_SAVE_ERROR';
export const TOURNAMENT_SAVE_RESULT_CLEAR = 'TOURNAMENT_SAVE_RESULT_CLEAR';
export const SET_SELECTED_TOURNAMENT_ID = 'SET_SELECTED_TOURNAMENT_ID';
export const UPDATE_TOURNAMENT = 'UPDATE_TOURNAMENT';

/**
 * Tournament Entries Actions
 */

export const ADD_NEW_ENTRY = 'ADD_NEW_ENTRY';
export const ENTRIES_ADD_ERROR = 'ENTRIES_ADD_ERROR';
export const ENTRIES_LOADED = 'ENTRIES_LOADED';
export const ENTRIES_LOAD_ERROR = 'ENTRIES_LOAD_ERROR';
export const ENTRIES_REQUEST_START = 'ENTRIES_REQUEST_START';
export const ENTRIES_REQUEST_END = 'ENTRIES_REQUEST_END';
export const ENTRIES_UPDATE_ERROR = 'ENTRIES_UPDATE_ERROR';
export const ENTRIES_ADD_LOCAL_RECORD = 'ENTRIES_ADD_LOCAL_RECORD';
export const ENTRIES_UPDATE_LOCAL_RECORD = 'ENTRIES_UPDATE_LOCAL_RECORD';
export const ENTRIES_DROP_LOCAL_RECORD = 'ENTRIES_DROP_LOCAL_RECORD';
export const ENTRY_DELETED = 'ENTRY_DELETED';
export const ENTRY_DELETE_ERROR = 'ENTRY_DELETE_ERROR';
export const ENTRY_DELETE_RESULT_CLEAR = 'ENTRY_DELETE_RESULT_CLEAR';
export const ENTRY_SAVED = 'ENTRY_SAVED';
export const ENTRY_SAVE_ERROR = 'ENTRY_SAVE_ERROR';
export const ENTRY_SAVE_RESULT_CLEAR = 'ENTRY_SAVE_RESULT_CLEAR';
export const SET_SELECTED_ENTRY_ID = 'SET_SELECTED_ENTRY_ID';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';

/**
 * Vote Contests Actions
 */

export const ADD_NEW_VOTECONTEST = 'ADD_NEW_VOTECONTEST';
export const VOTECONTESTS_ADD_ERROR = 'VOTECONTESTS_ADD_ERROR';
export const VOTECONTESTS_LOADED = 'VOTECONTESTS_LOADED';
export const VOTECONTESTS_LOAD_ERROR = 'VOTECONTESTS_LOAD_ERROR';
export const VOTECONTESTS_REQUEST_START = 'VOTECONTESTS_REQUEST_START';
export const VOTECONTESTS_REQUEST_END = 'VOTECONTESTS_REQUEST_END';
export const VOTECONTESTS_UPDATE_ERROR = 'VOTECONTESTS_UPDATE_ERROR';
export const VOTECONTESTS_ADD_LOCAL_RECORD = 'VOTECONTESTS_ADD_LOCAL_RECORD';
export const VOTECONTESTS_UPDATE_LOCAL_RECORD = 'VOTECONTESTS_UPDATE_LOCAL_RECORD';
export const VOTECONTESTS_DROP_LOCAL_RECORD = 'VOTECONTESTS_DROP_LOCAL_RECORD';
export const VOTECONTEST_DELETED = 'VOTECONTEST_DELETED';
export const VOTECONTEST_DELETE_ERROR = 'VOTECONTEST_DELETE_ERROR';
export const VOTECONTEST_DELETE_RESULT_CLEAR = 'VOTECONTEST_DELETE_RESULT_CLEAR';
export const VOTECONTEST_SAVED = 'VOTECONTEST_SAVED';
export const VOTECONTEST_SAVE_ERROR = 'VOTECONTEST_SAVE_ERROR';
export const VOTECONTEST_SAVE_RESULT_CLEAR = 'VOTECONTEST_SAVE_RESULT_CLEAR';
export const SET_SELECTED_VOTECONTEST_ID = 'SET_SELECTED_VOTECONTEST_ID';
export const UPDATE_VOTECONTEST = 'UPDATE_VOTECONTEST';

/**
 * User Actions
 */

export const USER_ACTION_REQUEST_END = 'USER_ACTION_REQUEST_END';
export const USER_ACTION_REQUEST_START = 'USER_ACTION_REQUEST_START';
export const USER_ACTION_ERROR = 'USER_ACTION_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR';
export const USER_REQUEST_START = 'USER_REQUEST_START';
export const USER_REQUEST_END = 'USER_REQUEST_END';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_REFRESH = 'USER_REFRESH';

/**
 * Users Actions (managing users, not the logged-in user)
 */

export const ADD_NEW_USER = 'ADD_NEW_USER';
export const USERS_ADD_ERROR = 'USERS_ADD_ERROR';
export const USERS_LOADED = 'USERS_LOADED';
export const USERS_LOAD_ERROR = 'USERS_LOAD_ERROR';
export const USERS_REQUEST_START = 'USERS_REQUEST_START';
export const USERS_REQUEST_END = 'USERS_REQUEST_END';
export const USERS_UPDATE_ERROR = 'USERS_UPDATE_ERROR';
export const USERS_ADD_LOCAL_RECORD = 'USERS_ADD_LOCAL_RECORD';
export const USERS_UPDATE_LOCAL_RECORD = 'USERS_UPDATE_LOCAL_RECORD';
export const USERS_DROP_LOCAL_RECORD = 'USERS_DROP_LOCAL_RECORD';
export const USER_DELETED = 'USER_DELETED';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';
export const USER_DELETE_RESULT_CLEAR = 'USER_DELETE_RESULT_CLEAR';
export const USER_SAVED = 'USER_SAVED';
export const USER_SAVE_ERROR = 'USER_SAVE_ERROR';
export const USER_SAVE_RESULT_CLEAR = 'USER_SAVE_RESULT_CLEAR';
export const SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID';
export const UPDATE_USER = 'UPDATE_USER';
