import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	PRODUCTS_ADD_ERROR,
	PRODUCTS_LOADED,
	PRODUCTS_LOAD_ERROR,
	PRODUCTS_REQUEST_END,
	PRODUCTS_REQUEST_START,
	PRODUCTS_UPDATE_ERROR,
	PRODUCTS_ADD_LOCAL_RECORD,
	PRODUCTS_UPDATE_LOCAL_RECORD,
	PRODUCTS_DROP_LOCAL_RECORD,
	PRODUCT_DELETED,
	PRODUCT_DELETE_ERROR,
	PRODUCT_DELETE_RESULT_CLEAR,
	PRODUCT_SAVED,
	PRODUCT_SAVE_ERROR,
	PRODUCT_SAVE_RESULT_CLEAR,
	SET_SELECTED_PRODUCT_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Product Actions
 */

export const getProducts = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().products;
		if (state.isRequesting) {
			// console.log('getProducts: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getProducts(ids, options);
			};
			dispatch(queueDeferredAction('products', deferred));
			return;
		}
		dispatch(productsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=products');
		} catch (err) {
			error = err;
		}

		dispatch(productsRequestEnd());

		if (error) {
			return dispatch(productsLoadError(error));
		}

		dispatch(productsLoaded({ products: response?.data }));
	};
};

export const loadProduct = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadProduct, no id!');
			return dispatch(
				productsLoadError({
					message: 'no id found to load product with'
				})
			);
		}
		console.log('loadProduct loadProduct loadProduct loadProduct ');
		console.log(id);
		const state = getState().products;
		if (state.isRequesting) {
			// console.log('getProducts: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadProduct(id);
			};
			dispatch(queueDeferredAction('products', deferred));
			return;
		}
		dispatch(productsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=products&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(productsRequestEnd());

		if (error) {
			return dispatch(productsLoadError(error));
		}

		console.log('loadProduct loadProduct loadProduct loadProduct ');
		console.log(response);

		dispatch(productsLoaded({ products: [response?.data] }));
	};
};

export const setSelectedProductId = id => ({
	type: SET_SELECTED_PRODUCT_ID,
	payload: { id }
});

export const addProduct = data => {
	return async (dispatch, getState) => {
		const state = getState().products;
		if (state.isRequesting) {
			const deferred = () => {
				addProduct(data);
			};
			dispatch(queueDeferredAction('products', deferred));
			return;
		}
		dispatch(productsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=products', { data });
		} catch (err) {
			error = err;
		}

		console.log('addProduct, response');
		console.log(response);

		dispatch(productsRequestEnd());

		if (error) {
			dispatch(productSaveError(responses.PRODUCT_SAVE_ERROR));
			return dispatch(productsAddError(error));
		}

		dispatch(productSaved(responses.PRODUCT_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addProductLocalRecord(data));
		}
	};
};

export const updateProduct = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateProduct, no id!');
			return dispatch(
				productsUpdateError({
					message: 'no id found to update product with'
				})
			);
		}
		const state = getState().products;
		if (state.isRequesting) {
			const deferred = () => {
				updateProduct(id, data);
			};
			dispatch(queueDeferredAction('products', deferred));
			return;
		}
		dispatch(productsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=products&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(productsRequestEnd());

		console.log('updateProduct ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(productSaveError(responses.PRODUCT_SAVE_ERROR));
			return dispatch(productsUpdateError(error));
		}

		dispatch(productSaved(responses.PRODUCT_SAVED));
		dispatch(updateProductLocalRecord(id, data));
	};
};

export const deleteProduct = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteProduct, no id!');
			return false; // dispatch(productsUpdateError({ message: 'no id found to update product with' }));
		}
		const state = getState().products;
		if (state.isRequesting) {
			const deferred = () => {
				deleteProduct(id);
			};
			dispatch(queueDeferredAction('products', deferred));
			return;
		}
		dispatch(productsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=products&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(productsRequestEnd());

		console.log('deleteProduct ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(productDeleteError(responses.PRODUCT_DELETE_ERROR));
			// return dispatch(productsUpdateError(error));
		}

		dispatch(productDeleted(responses.PRODUCT_DELETED));
		dispatch(dropProductLocalRecord(id));
	};
};

export const addProductLocalRecord = data => ({
	type: PRODUCTS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateProductLocalRecord = (id, data) => ({
	type: PRODUCTS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const productsLoaded = ({ products }) => ({
	type: PRODUCTS_LOADED,
	payload: { products }
});

export const productSaved = responseMsg => ({
	type: PRODUCT_SAVED,
	payload: responseMsg
});

export const productSaveError = responseMsg => ({
	type: PRODUCT_SAVE_ERROR,
	payload: responseMsg
});

export const dropProductLocalRecord = id => ({
	type: PRODUCTS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const productDeleted = responseMsg => ({
	type: PRODUCT_DELETED,
	payload: responseMsg
});

export const productDeleteError = responseMsg => ({
	type: PRODUCT_DELETE_ERROR,
	payload: responseMsg
});

export const productClearSaveResult = () => ({
	type: PRODUCT_SAVE_RESULT_CLEAR
});

export const productClearDeleteResult = () => ({
	type: PRODUCT_DELETE_RESULT_CLEAR
});

export const productsLoadError = error => ({
	type: PRODUCTS_LOAD_ERROR,
	payload: { error }
});

export const productsAddError = error => ({
	type: PRODUCTS_ADD_ERROR,
	payload: { error }
});

export const productsUpdateError = error => ({
	type: PRODUCTS_UPDATE_ERROR,
	payload: { error }
});

export const productsRequestEnd = () => ({
	type: PRODUCTS_REQUEST_END,
	payload: false
});

export const productsRequestStart = () => ({
	type: PRODUCTS_REQUEST_START,
	payload: true
});
