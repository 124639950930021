export const ADMIN_SAVED = 'Admin was successfully saved';
export const ADMIN_SAVE_ERROR = 'Failed to save the admin';
export const ADMIN_DELETED = 'Admin was successfully deleted';
export const ADMIN_DELETE_ERROR = 'Failed to delete the admin';

export const ARTICLE_SAVED = 'Article was successfully saved';
export const ARTICLE_SAVE_ERROR = 'Failed to save the article';
export const ARTICLE_DELETED = 'Article was successfully deleted';
export const ARTICLE_DELETE_ERROR = 'Failed to delete the article';

export const BRAND_SAVED = 'Brand was successfully saved';
export const BRAND_SAVE_ERROR = 'Failed to save the brand';
export const BRAND_DELETED = 'Brand was successfully deleted';
export const BRAND_DELETE_ERROR = 'Failed to delete the brand';

export const CANDIDATE_SAVED = 'Candidate was successfully saved';
export const CANDIDATE_SAVE_ERROR = 'Failed to save the candidate';
export const CANDIDATE_DELETED = 'Candidate was successfully deleted';
export const CANDIDATE_DELETE_ERROR = 'Failed to delete the candidate';

export const CAR_SAVED = 'Car was successfully saved';
export const CAR_SAVE_ERROR = 'Failed to save the car';
export const CAR_DELETED = 'Car was successfully deleted';
export const CAR_DELETE_ERROR = 'Failed to delete the car';

export const CHARITY_SAVED = 'Charity was successfully saved';
export const CHARITY_SAVE_ERROR = 'Failed to save the charity';
export const CHARITY_DELETED = 'Charity was successfully deleted';
export const CHARITY_DELETE_ERROR = 'Failed to delete the charity';

export const DONATIONMATRIX_SAVED = 'Donation Matrix was successfully saved';
export const DONATIONMATRIX_SAVE_ERROR = 'Failed to save the donation matrix';
export const DONATIONMATRIX_DELETED = 'Donation Matrix was successfully deleted';
export const DONATIONMATRIX_DELETE_ERROR = 'Failed to delete the donation matrix';

export const DRIVER_SAVED = 'Driver was successfully saved';
export const DRIVER_SAVE_ERROR = 'Failed to save the driver';
export const DRIVER_DELETED = 'Driver was successfully deleted';
export const DRIVER_DELETE_ERROR = 'Failed to delete the driver';

export const EMAIL_TEMPLATE_SAVED = 'Email template was successfully saved';
export const EMAIL_TEMPLATE_SAVE_ERROR = 'Failed to save the email template';
export const EMAIL_TEMPLATE_DELETED = 'Email template was successfully deleted';
export const EMAIL_TEMPLATE_DELETE_ERROR = 'Failed to delete the email template';

export const ENTRY_SAVED = 'Entry was successfully saved';
export const ENTRY_SAVE_ERROR = 'Failed to save the entry';
export const ENTRY_DELETED = 'Entry was successfully deleted';
export const ENTRY_DELETE_ERROR = 'Failed to delete the entry';

export const EVENT_SAVED = 'Event was successfully saved';
export const EVENT_SAVE_ERROR = 'Failed to save the event';
export const EVENT_DELETED = 'Event was successfully deleted';
export const EVENT_DELETE_ERROR = 'Failed to delete the event';

export const JACKPOTRESULT_SAVED = 'Jackpot Result was successfully saved';
export const JACKPOTRESULT_SAVE_ERROR = 'Failed to save the result';
export const JACKPOTRESULT_DELETED = 'Jackpot Result was successfully deleted';
export const JACKPOTRESULT_DELETE_ERROR = 'Failed to delete the result';

export const LOCATION_SAVED = 'Location was successfully saved';
export const LOCATION_SAVE_ERROR = 'Failed to save the location';
export const LOCATION_DELETED = 'Location was successfully deleted';
export const LOCATION_DELETE_ERROR = 'Failed to delete the location';

export const PRIZEDRAW_SAVED = 'Prize draw was successfully saved';
export const PRIZEDRAW_SAVE_ERROR = 'Failed to save the prize draw';
export const PRIZEDRAW_DELETED = 'Prize draw was successfully deleted';
export const PRIZEDRAW_DELETE_ERROR = 'Failed to delete the prize draw';

export const PRODUCT_SAVED = 'Product was successfully saved';
export const PRODUCT_SAVE_ERROR = 'Failed to save the product';
export const PRODUCT_DELETED = 'Product was successfully deleted';
export const PRODUCT_DELETE_ERROR = 'Failed to delete the product';

export const RACE_SAVED = 'Race was successfully saved';
export const RACE_SAVE_ERROR = 'Failed to save the race';
export const RACE_DELETED = 'Race was successfully deleted';
export const RACE_DELETE_ERROR = 'Failed to delete the race';

export const RAFFLEPRIZE_SAVED = 'Raffle Prize was successfully saved';
export const RAFFLEPRIZE_SAVE_ERROR = 'Failed to save the raffle prize';
export const RAFFLEPRIZE_DELETED = 'Raffle Prize was successfully deleted';
export const RAFFLEPRIZE_DELETE_ERROR = 'Failed to delete the raffle prize';

export const TOURNAMENT_SAVED = 'Tournament was successfully saved';
export const TOURNAMENT_SAVE_ERROR = 'Failed to save the tournament';
export const TOURNAMENT_DELETED = 'Tournament was successfully deleted';
export const TOURNAMENT_DELETE_ERROR = 'Failed to delete the tournament';

export const VOTECONTEST_SAVED = 'Vote contest was successfully saved';
export const VOTECONTEST_SAVE_ERROR = 'Failed to save the vote contest';
export const VOTECONTEST_DELETED = 'Vote contest was successfully deleted';
export const VOTECONTEST_DELETE_ERROR = 'Failed to delete the vote contest';

export const SPONSOR_SAVED = 'Sponsor was successfully saved';
export const SPONSOR_SAVE_ERROR = 'Failed to save the sponsor';
export const SPONSOR_DELETED = 'Sponsor was successfully deleted';
export const SPONSOR_DELETE_ERROR = 'Failed to delete the sponsor';

export const USER_SAVED = 'User was successfully saved';
export const USER_SAVE_ERROR = 'Failed to save the user';
export const USER_DELETED = 'User was successfully deleted';
export const USER_DELETE_ERROR = 'Failed to delete the user';
