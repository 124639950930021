/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useProducts from '../../../hooks/useProducts';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { showCityState } from '../../../utils/datasource';
import { ListTable } from '../../ThemeUiStyled';
import useEvents from 'hooks/useEvents';

const ProductsList = props => {
	const { data, error } = props;
	const {
		selectProduct,
		deleteProduct,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useProducts();

	const { events, getEvents } = useEvents();

	useEffect(() => {
		if (!events?.length) {
			getEvents();
		}
	}, [events, getEvents]);

	const history = useHistory();

	const [productToDelete, setProductToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>No data found, add something to the list.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting products....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectProduct(item._id);
		history.push('/data/products/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectProduct(item._id);
		history.push('/data/products/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setProductToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setProductToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		productToDelete && deleteProduct(productToDelete._id);
		setProductToDelete(null);
		setShowDeleteModal(false);
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '2px 5px',
		fontSize: '12px',

		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const displayExtrasInfo = item => {
		let display = [];
		if (item.extras?.length) {
			for (const extra of item.extras) {
				display.push(`${extra.count} ${extra.type}`);
			}
			return display.join(', ');
		}
		return 'none';
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='product'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Product</td>
						<td>Type</td>
						<td>Event</td>
						<td>Price</td>
						<td>Extras</td>
						<td>Enabled</td>
						<td>Actions</td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						const myEvent = events.find(event => event._id === item.eventId);
						const extrasDisplay = displayExtrasInfo(item);
						return (
							<tr key={idx}>
								<td style={{ fontSize: '13px' }}>{item.label}</td>
								<td style={{ fontSize: '13px' }}>{item.pType}</td>
								<td style={{ fontSize: '11px' }}>{myEvent?.label || '-'}</td>
								<td>{(item.priceCents / 100).toFixed(2)}</td>
								<td style={{ fontSize: '11px' }}>{extrasDisplay}</td>
								<td>{item.enabled && 'x'}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default ProductsList;
