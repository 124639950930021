import React from 'react';
import { Box, Button, Input, Label, Spinner } from 'theme-ui';

const AddressFields = props => {
	const { data, onFieldUpdate, addressMultiLine = true } = props;
	return (
		<>
			<Label htmlFor='addrLine1'>Address Line 1</Label>
			<Input name='addrLine1' mb={3} value={data?.addrLine1 || ''} onChange={onFieldUpdate} />
			<Label htmlFor='addrLine2'>Address Line 2</Label>
			<Input name='addrLine2' mb={3} value={data?.addrLine2 || ''} onChange={onFieldUpdate} />
			<Label htmlFor='city'>City</Label>
			<Input name='city' mb={3} value={data?.city || ''} onChange={onFieldUpdate} />
			<Label htmlFor='state'>State</Label>
			<Input name='state' mb={3} value={data?.state || ''} onChange={onFieldUpdate} />
			<Label htmlFor='postal'>Postal Code</Label>
			<Input name='postal' mb={3} value={data?.postal || ''} onChange={onFieldUpdate} />
			<Label htmlFor='country'>Country</Label>
			<Input name='country' mb={3} value={data?.country || ''} onChange={onFieldUpdate} />
		</>
	);
};

export default AddressFields;
