/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Input, Label, Spinner, Textarea } from 'theme-ui';
import useRafflePrizes from '../../../hooks/useRafflePrizes';
import useSponsors from '../../../hooks/useSponsors';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const RafflePrizesEdit = props => {
	const { data, error } = props;
	const {
		selectedRafflePrizeId,
		selectRafflePrize,
		getRafflePrize,
		saveRafflePrize,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useRafflePrizes();
	const {
		getSponsors,
		sponsors,
		error: sponsorError,
		isProcessing: isProcessingEntities
	} = useSponsors();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [hasRequestedSponsors, setHasRequestedSponsors] = useState(false);

	useEffect(() => {
		// unload the selected raffle on "unmount"
		return () => {
			selectRafflePrize();
		};
	}, [selectRafflePrize]);

	useEffect(() => {
		if (!hasRequestedSponsors) {
			setHasRequestedSponsors(true);
			getSponsors();
		}
	}, [hasRequestedSponsors, getSponsors, setHasRequestedSponsors]);

	useEffect(() => {
		if (!data && selectedRafflePrizeId) {
			console.log('getRafflePrize!!!!' + selectedRafflePrizeId);
			getRafflePrize(selectedRafflePrizeId);
		} else if (data?._id !== selectedRafflePrizeId) {
			console.log('getRafflePrize!!!!' + selectedRafflePrizeId);
			getRafflePrize(selectedRafflePrizeId);
		}
	}, [data, selectedRafflePrizeId, getRafflePrize]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveRafflePrize(selectedRafflePrizeId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading rafflePrize....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit RafflePrize:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>RafflePrize Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label mb={3} htmlFor='isGrandPrize'>
								{/*
                                <Checkbox
                                    name="isGrandPrize"
                                    value={localData?.isGrandPrize}
                                    checked={localData?.isGrandPrize}
                                    onChange={() => alert('changed')}
                                />
                                */}
								<input
									type='checkbox'
									onChange={onFieldUpdate}
									name='isGrandPrize'
									value={localData?.isGrandPrize || false}
									checked={localData?.isGrandPrize || false}
								/>
								Check this box if this is a Grand Prize
							</Label>
							<Label htmlFor='description'>Description</Label>
							<Textarea
								rows={4}
								name='description'
								mb={3}
								value={localData?.description || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='value'>Cash Value</Label>
							<Input
								name='value'
								mb={3}
								value={localData?.value || ''}
								onChange={onFieldUpdate}
							/>

							<Label htmlFor='entity'>Donated By</Label>
							<SelectOne
								data={sponsors}
								value={localData?.entity}
								name='entity'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No sponsors found to choose from.'
							/>
							<Label htmlFor='buyLink'>Ultimus Store Link</Label>
							<Input
								name='buyLink'
								mb={3}
								value={localData?.buyLink || ''}
								onChange={onFieldUpdate}
							/>
							<EditorSaveButton isProcessing={isProcessing} label='Raffle Prize' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Raffle Prize image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default RafflePrizesEdit;
