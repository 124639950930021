/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Input, Label, Spinner, Textarea } from 'theme-ui';
import useUsers from '../../../hooks/useUsers';
import useSponsors from '../../../hooks/useSponsors';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const UsersEdit = props => {
	const { data, error } = props;
	const {
		selectedUserId,
		selectUser,
		getUser,
		saveUser,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useUsers();

	const dataCopy = useMemo(() => {
		if (data) {
			if (data._emailIdentity) {
				data.email = data._emailIdentity[0].email;
			}
			// FIXME: artifacts from editing previously, fix this....
			delete data.password;
			return data;
		}
		return null;
	}, [data]);

	const [localData, setLocalData] = useState(dataCopy);
	const [localSaveResult, setLocalSaveResult] = useState(null);
	const [formErrors, setFormErrors] = useState(null);

	useEffect(() => {
		// unload the selected raffle on "unmount"
		return () => {
			selectUser();
		};
	}, [selectUser]);

	useEffect(() => {
		if (!data && selectedUserId) {
			console.log('getUser!!!!' + selectedUserId);
			getUser(selectedUserId);
		} else if (data?._id !== selectedUserId) {
			console.log('getUser!!!!' + selectedUserId);
			getUser(selectedUserId);
		}
	}, [data, selectedUserId, getUser]);

	useEffect(() => {
		if (!localData && dataCopy) {
			setLocalData(dataCopy);
		}
	}, [localData, dataCopy, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		const errors = [];
		if (localData.password) {
			if (localData.password !== localData.passwordv) {
				errors.push('Passwords must match');
			} else {
				delete localData.passwordv;
			}
		} else {
			delete localData.password;
			delete localData.passwordv;
		}
		if (errors.length) {
			return setFormErrors(errors);
		}
		saveUser(selectedUserId, localData);
		setFormErrors(null);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading user....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit User:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: ['100%', '100%', '60%'],
								mx: '10px'
							}}>
							<Label htmlFor='name'>Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='value'>Email</Label>
							<Input
								name='email'
								mb={3}
								value={localData?.email || ''}
								onChange={onFieldUpdate}
							/>
							<h3>To update a user's password, type the new one twice:</h3>
							<Label htmlFor='password'>Password</Label>
							<Input
								name='password'
								mb={3}
								value={localData?.password || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='passwordv'>Verify Password</Label>
							<Input
								name='passwordv'
								mb={3}
								value={localData?.passwordv || ''}
								onChange={onFieldUpdate}
							/>

							{/*<Label htmlFor='entity'>Donated By</Label>
							<SelectOne
								data={sponsors}
								value={localData?.entity}
								name='entity'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No sponsors found to choose from.'
						/>*/}
							<EditorSaveButton isProcessing={isProcessing} label='User' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
							{formErrors?.length &&
								formErrors.map((FE, idx) => <p key={idx}>{FE}</p>)}
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: ['100%', '100%', '40%'],
								mx: '10px'
							}}>
							<h3>Avatar:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default UsersEdit;
