/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner, useThemeUI } from 'theme-ui';
import useLocations from '../../../hooks/useLocations';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import AddressFields from '../../segments/AddressFields';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

const LocationsEdit = props => {
	const { data, error } = props;
	const {
		selectedLocationId,
		getLocation,
		saveLocation,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useLocations();
	const [localData, setLocalData] = useState(data);

	useEffect(() => {
		if (!data && selectedLocationId) {
			console.log('getLocation!!!!' + selectedLocationId);
			getLocation(selectedLocationId);
		} else if (data?._id !== selectedLocationId) {
			console.log('getLocation!!!!' + selectedLocationId);
			getLocation(selectedLocationId);
		}
	}, [data, selectedLocationId, getLocation]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveLocation(selectedLocationId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading location....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Location:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Venue</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<AddressFields onFieldUpdate={onFieldUpdate} data={localData} />
							<EditorSaveButton isProcessing={isProcessing} label='Location' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Location Photo:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default LocationsEdit;
