import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner } from 'theme-ui';
import useAdmins from '../../../hooks/useAdmins';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
// import AddressFields from '../../segments/AddressFields';
// import ImageUploader from '../../segments/ImageUploader';
// import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import { getOnFieldUpdate } from '../../../utils/datasource';

const AdminsAdd = props => {
	const { data, error } = props;
	const {
		isProcessing,
		saveAdmin,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useAdmins();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	// const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveAdmin(null, localData);
	};

	if (error) {
		return <p>Oops, error!</p>;
	}

	return (
		<div>
			<h2>Add a New Admin:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='phone'>Mobile Phone</Label>
							<Input
								name='phone'
								mb={3}
								value={localData?.phone || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='email'>Email</Label>
							<Input
								name='email'
								mb={3}
								value={localData?.email || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='password'>Password</Label>
							<Input
								name='password'
								type='password'
								mb={3}
								value={localData?.password || ''}
								onChange={onFieldUpdate}
							/>
							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Admin'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							{/*
                            <h3>Admin Photo:</h3>
                            <ImageUploader
                                id={getLocalImageId(localData)}
                                onChange={updateImageData}
                            />
                            */}
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default AdminsAdd;
