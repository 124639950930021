import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Select, Flex, Box, Button } from 'theme-ui';

export const RemoveItemButton = styled(Button)`
	background-color: #c3172f;
	color: #fff;
	font-weight: bold;
	padding: 4px 6px;
	font-size: 10px;
	border-radius: 3px;
`;

export const AddItemButton = styled(Button)`
	background-color: #44cc10;
	color: #fff;
	font-weight: bold;
	padding: 4px 6px;
	font-size: 10px;
	border-radius: 3px;
`;

const SelectOneList = props => {
	const {
		data,
		value,
		name: selectName,
		dataLabelProp,
		dataValueProp,
		onFieldUpdate,
		useEmptySelector,
		emptySelctorMsg,
		noDataMsg
	} = props;

	const [stagedValue, setStagedValue] = useState('');

	const _onSelectUpdate = event => {
		const { value } = event?.target;
		setStagedValue(value);
	};

	const addItem = e => {
		e.preventDefault();
		const newListItems = [...value, stagedValue];
		setStagedValue('');
		onFieldUpdate({ target: { name: selectName, value: newListItems } });
	};

	const removeItem = (e, item) => {
		e.preventDefault();
		const newListItems = value.filter(ITEM => ITEM !== item);
		onFieldUpdate({ target: { name: selectName, value: newListItems } });
	};

	const removeList = useMemo(() => {
		if (!value.length) {
			return <h4>no items chosen yet</h4>;
		}

		return value.map((item, idx) => {
			const myItem = data.find(D => D[dataValueProp] === item);
			return (
				<Flex key={idx}>
					<Box p={2} sx={{ flex: '1 1 auto' }}>
						<div>{myItem?.[dataLabelProp] || 'not found'}</div>
					</Box>
					<Box p={2}>
						<RemoveItemButton onClick={e => removeItem(e, item)}>x</RemoveItemButton>
					</Box>
				</Flex>
			);
		});
	}, [value]);

	const localData = useMemo(() => {
		console.warn('calculate localData');
		const list =
			(data?.length &&
				data.filter(D => {
					return !value.includes(D[dataValueProp]);
				})) ||
			[];
		console.log(data);
		console.log(value);
		console.log(list);
		return list;
	}, [value, data]);

	const optionsList = useMemo(() => {
		if (!localData.length) {
			return null;
		}
		return localData.map((S, idx) => {
			return (
				<option key={idx} value={S[dataValueProp]}>
					{S[dataLabelProp]}
				</option>
			);
		});
	}, [value, data]);

	if (!data?.length) {
		return <h4>{noDataMsg}</h4>;
	}

	return (
		<>
			{localData.length ? (
				<Flex>
					<Box sx={{ flex: '1 1 auto' }}>
						<Select onChange={_onSelectUpdate} value={stagedValue} name={selectName}>
							{useEmptySelector && <option>{emptySelctorMsg}</option>}
							{optionsList}
						</Select>
					</Box>
					<Box p={2}>
						<AddItemButton onClick={e => addItem(e)}>Add Item</AddItemButton>
					</Box>
				</Flex>
			) : (
				<h4>All available items are selected</h4>
			)}
			<hr />
			{removeList}
		</>
	);
};

SelectOneList.defaultProps = {
	value: [],
	useEmptySelector: true,
	dataLabelProp: 'name',
	dataValueProp: '_id',
	emptySelctorMsg: 'select one'
};

export default SelectOneList;
