/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useCars from '../../../hooks/useCars';
import useSponsors from '../../../hooks/useSponsors';
import useDrivers from '../../../hooks/useDrivers';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { ListTable } from '../../ThemeUiStyled';

const CarsList = props => {
	const { data, error } = props;
	const {
		selectCar,
		deleteCar,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useCars();
	const {
		getSponsors,
		sponsors,
		error: sponsorError,
		isProcessing: isProcessingEntities
	} = useSponsors();
	const {
		getDrivers,
		drivers,
		error: driverError,
		isProcessing: isProcessingDrivers
	} = useDrivers();

	const history = useHistory();
	const [carToDelete, setCarToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	useEffect(() => {
		getSponsors(data?.map(D => D.entity));
	}, [data]);

	useEffect(() => {
		getDrivers(data?.map(D => D.driver));
	}, [data]);

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>No data found, add something to the list.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting cars....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectCar(item._id);
		history.push('/data/cars/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectCar(item._id);
		history.push('/data/cars/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setCarToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setCarToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		carToDelete && deleteCar(carToDelete._id);
		setCarToDelete(null);
		setShowDeleteModal(false);
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '2px 5px',
		fontSize: '12px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getSponsorName = id => {
		const mySponsor = sponsors?.find(S => S._id === id);
		return mySponsor?.name || id;
	};

	const getDriverName = id => {
		const myDriver = drivers?.find(D => D._id === id);
		return myDriver?.name || id;
	};

	const getDriveriRacingId = id => {
		const myDriver = drivers?.find(D => D._id === id);
		return myDriver?.iRacingId || id;
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='car'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>#</td>
						<td>Car</td>
						<td>Driver</td>
						<td>iRacing ID</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{item.number}</td>
								<td>{item.name}</td>
								<td>{getDriverName(item.driver || 'none')}</td>
								<td>{getDriveriRacingId(item.driver || 'none')}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default CarsList;
