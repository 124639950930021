/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Box, Flex, Input, Label, Spinner, Textarea, useThemeUI } from 'theme-ui';
import useCandidates from '../../../hooks/useCandidates';
import useVoteContests from '../../../hooks/useVoteContests';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import DatePicker from '../../segments/DatePicker';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import ImageUploader from '../../segments/ImageUploader';
import VoteContestCandidateCategoriesSetup from '../../tools/EventBuilders/SetupBuilders/VoteContestCandidateCategoriesSetup';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';

export const RegencyCandidateGroups = [
	'Classic',
	'Curve',
	'Jr Miss',
	'Jr Teen',
	'Little Miss',
	'Miss',
	'Mrs',
	'Ms',
	'Petite',
	'Teen',
	'Tiny'
];

const CandidatesEdit = props => {
	const { data, error } = props;
	const {
		selectedCandidateId,
		getCandidate,
		saveCandidate,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useCandidates();
	const [localData, setLocalData] = useState(data);

	const {
		getVoteContests,
		voteContests,
		error: voteContestError,
		isProcessing: isProcessingVoteContests
	} = useVoteContests();
	const [hasRequestedVoteContests, setHasRequestedVoteContests] = useState(false);

	useEffect(() => {
		if (!hasRequestedVoteContests) {
			setHasRequestedVoteContests(true);
			getVoteContests();
		}
	}, [hasRequestedVoteContests, getVoteContests, setHasRequestedVoteContests]);

	useEffect(() => {
		if (!data && selectedCandidateId) {
			console.log('getCandidate!!!!' + selectedCandidateId);
			getCandidate(selectedCandidateId);
		} else if (data?._id !== selectedCandidateId) {
			console.log('getCandidate!!!!' + selectedCandidateId);
			getCandidate(selectedCandidateId);
		}
	}, [data, selectedCandidateId, getCandidate]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveCandidate(selectedCandidateId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading candidate....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit Candidate:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Candidate Name</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='birthday'>Birthday</Label>
							<DatePicker
								value={localData?.birthday}
								name='birthday'
								onFieldUpdate={onFieldUpdate}
							/>

							<Label htmlFor='location'>General Location</Label>
							<Input
								name='location'
								mb={3}
								value={localData?.location || ''}
								onChange={onFieldUpdate}
							/>

							<Label htmlFor='title'>Title</Label>
							<Input
								name='title'
								mb={3}
								value={localData?.title || ''}
								onChange={onFieldUpdate}
							/>
							{/*
							<Label htmlFor='email'>Email</Label>
							<Input
								name='email'
								mb={3}
								value={localData?.email || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='city'>City</Label>
							<Input
								name='city'
								mb={3}
								value={localData?.city || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='state'>State / Province</Label>
							<Input
								name='state'
								mb={3}
								value={localData?.state || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='country'>Country</Label>
							<Input
								name='country'
								mb={3}
								value={localData?.country || ''}
								onChange={onFieldUpdate}
							/>
							*/}
							<EditorSaveButton isProcessing={isProcessing} label='Candidate' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Candidate Image:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
							<Label htmlFor='event'>What Contest?</Label>
							<SelectOne
								data={voteContests}
								value={localData?.event}
								name='event'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No contests found to choose from.'
							/>
							<h3>Select what categories this contestant is eligible for:</h3>
							{localData?.event && voteContests.length ? (
								<>
									<Label htmlFor='candidateGroup'>What Group / Segment?</Label>
									<SelectOne
										data={RegencyCandidateGroups}
										value={localData?.candidateGroup}
										name='candidateGroup'
										onFieldUpdate={onFieldUpdate}
										noDataMsg='No groups found to choose from.'
									/>

									<VoteContestCandidateCategoriesSetup
										voteContests={voteContests}
										localData={localData}
										onFieldUpdate={onFieldUpdate}
									/>
								</>
							) : (
								<p>Please select an event to see available categories</p>
							)}
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default CandidatesEdit;
