/** @jsx jsx */
import {
	jsx,
	// useThemeUI,
	Box,
	Checkbox as ThemeUiCheckbox
} from 'theme-ui';
import React from 'react';
import styled from 'styled-components';

export const DatasourceContainer = styled.div``;

export const EditorContainerSimple = styled.div`
	/*
	max-width: 500px;
	padding: 20px;
	border: 1px solid #111;
	background-color: #222;
	*/
`;

export const EditorContainer = props => {
	const { _sx, children } = props;
	if (!_sx) {
		return (
			<EditorContainerSimple
				sx={{
					width: ['100%', '65%', '40%']
				}}>
				{children}
			</EditorContainerSimple>
		);
	}

	const baseStyles = {
		// p: '20px',
		// border: '1px solid #111',
		// backgroundColor: '#222'
		width: ['100%', '65%', '40%']
	};

	const sx = Object.assign({}, baseStyles, _sx);

	return <Box sx={sx}>{children}</Box>;
};

export const EditorButtonContainer = styled.div`
	margin: 1em 0 0;
`;

export const Devnote = styled.p`
	font-size: 0.75em;
	font-style: italic;
`;

export const ListTable = styled.table`
	width: 100%;
	margin-right: 32px;
	border-collapse: collapse;
	tr {
		margin-bottom: 6px;
		padding-bottom: 6px;
	}
	tbody tr {
		border-bottom: 1px solid #ddd;
	}
	td {
		padding: 6px;
	}
	thead {
		background-color: #f6f6f6;
	}
	thead tr {
		border-bottom: 3px solid #ddd;
	}
	thead td {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 85%;
	}
`;

export const Checkbox = props => {
	const { onFieldUpdate, name, localData } = props;
	return (
		<span
			sx={{
				cursor: 'pointer'
			}}
			onClick={() =>
				onFieldUpdate({
					target: {
						name,
						value: !localData?.[name]
					}
				})
			}>
			<ThemeUiCheckbox
				name={name}
				value={localData?.[name] ? true : false}
				checked={localData?.[name] ? true : false}
				onChange={onFieldUpdate}
			/>
		</span>
	);
};

export const ViewTable = styled.table`
	border-collapse: collapse;
	tr {
		margin-bottom: 6px;
		padding-bottom: 6px;
	}
	td {
		font-size: 1.5em;
		font-weight: bold;
	}
	tr td:first-child {
		font-weight: normal;
		font-size: 1.25em;
		text-align: right;
		padding-right: 6px;
	}
	/*
    tbody tr {
        border-bottom: 1px solid #ddd;
    }
    */
`;
