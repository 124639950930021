/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useMemo } from 'react';
import { Box, Button, Input, Label, Spinner, useThemeUI } from 'theme-ui';
import useJackpotResults from '../../../hooks/useJackpotResults';
import useRaces from '../../../hooks/useRaces';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
// import { getJackpotResults } from '../../../redux/actions/jackpotResults';
import { EditorContainer } from '../../ThemeUiStyled';
import SelectOne from '../../segments/SelectOne';
import EditorSaveButton from '../../segments/EditorSaveButton';
import DatePicker from '../../segments/DatePicker';
import { getOnFieldUpdate } from '../../../utils/datasource';
import { RACETYPES } from '../../../constants';

const JackpotResultsEdit = props => {
	const { data, error } = props;
	const {
		selectedJackpotResultId,
		getJackpotResult,
		saveJackpotResult,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useJackpotResults();
	const [localData, setLocalData] = useState(data);

	const { getRaces, races, error: raceError, isProcessing: isProcessingRaces } = useRaces();

	const [hasRequestedRaces, setHasRequestedRaces] = useState(false);

	useEffect(() => {
		if (!data && selectedJackpotResultId) {
			getJackpotResult(selectedJackpotResultId);
		}
	}, [data, selectedJackpotResultId, getJackpotResult]);

	useEffect(() => {
		if (!hasRequestedRaces) {
			setHasRequestedRaces(true);
			getRaces({ raceType: RACETYPES.GHA_JACKPOT });
		}
	}, [hasRequestedRaces, getRaces, setHasRequestedRaces]);

	useEffect(() => {
		if (!data && selectedJackpotResultId) {
			console.log('getJackpotResult!!!!' + selectedJackpotResultId);
			getJackpotResult(selectedJackpotResultId);
		} else if (data?._id !== selectedJackpotResultId) {
			console.log('getJackpotResult!!!!' + selectedJackpotResultId);
			getJackpotResult(selectedJackpotResultId);
		}
	}, [data, selectedJackpotResultId, getJackpotResult]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveJackpotResult(selectedJackpotResultId, localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading jackpotResult....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit JackpotResult:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '65%', '40%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					{!localData?.isLocked && (
						<>
							<Label htmlFor='jackpotDate'>Date</Label>
							<DatePicker
								value={localData?.jackpotDate}
								name='jackpotDate'
								onFieldUpdate={onFieldUpdate}
							/>
							<Label htmlFor='race'>Race</Label>
							<SelectOne
								data={races}
								value={localData?.race}
								name='race'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No races found to choose from.'
							/>
						</>
					)}
					{localData?.isLocked && (
						<p>This jackpot has been picked already, no editing allowed sorry.</p>
					)}
					<Label mb={3} htmlFor='isLocked'>
						{/*
                                <Checkbox
                                    name="isLocked"
                                    value={localData?.isLocked}
                                    checked={localData?.isLocked}
                                    onChange={() => alert('changed')}
                                />
                                */}
						<input
							type='checkbox'
							onChange={onFieldUpdate}
							name='isLocked'
							value={localData?.isLocked || false}
							checked={localData?.isLocked || false}
						/>
						Locked (temporary allowing for testing)
					</Label>
					<EditorSaveButton isProcessing={isProcessing} label='Jackpot Result' />
					<UiStatusMessageDisplay
						message={uiStatusMessageSaveResult}
						consumed={uiStatusSaveMessageConsumed}
					/>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default JackpotResultsEdit;
