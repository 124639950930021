/** @jsx jsx */
import { Box, jsx, Styled } from 'theme-ui';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner, useThemeUI } from 'theme-ui';
import useUsers from '../../../hooks/useUsers';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';
import { getEmailFromUser } from './List';

const UsersView = props => {
	const { data, error } = props;
	const { selectedUserId, getUser } = useUsers();

	const { theme } = useThemeUI();

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		mb: '0.3em',
		fontSize: '0.65em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	console.log('UsersView');
	console.log(selectedUserId, data, error);

	useEffect(() => {
		if (!data && selectedUserId) {
			console.log('getUser!!!!' + selectedUserId);
			getUser(selectedUserId);
		}
	}, [data, selectedUserId, getUser]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading user....</p>
			</>
		);
	}

	const fanRole = data._roles?.find(R => R.role === 'FAN');

	return (
		<div>
			<h2>User:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Email: </td>
						<td>{getEmailFromUser(data)}</td>
					</tr>
					{fanRole && (
						<>
							<tr>
								<td>GHA Jackpot Credits: </td>
								<td>{fanRole.ghaJackpotCredits}</td>
							</tr>
							<tr>
								<td>GHA Raffle Tickets: </td>
								<td>{fanRole.ghaRaffleTickets}</td>
							</tr>
						</>
					)}
					<tr>
						<td>Actions:</td>
						<td>
							<Link
								sx={linkSx}
								to={`/tools/comms/send-email?user=${data._id}&staticTemplate=welcomeEmail`}>
								Send Welcome Email
							</Link>
							<br />
							<Link
								sx={linkSx}
								to={`/tools/comms/send-email?user=${data._id}&customMessage=true`}>
								Send Custom Message
							</Link>
						</td>
					</tr>
				</tbody>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default UsersView;
