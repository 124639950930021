import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	EVENTS_ADD_ERROR,
	EVENTS_LOADED,
	EVENTS_LOAD_ERROR,
	EVENTS_REQUEST_END,
	EVENTS_REQUEST_START,
	EVENTS_UPDATE_ERROR,
	EVENTS_ADD_LOCAL_RECORD,
	EVENTS_UPDATE_LOCAL_RECORD,
	EVENTS_DROP_LOCAL_RECORD,
	EVENT_DELETED,
	EVENT_DELETE_ERROR,
	EVENT_DELETE_RESULT_CLEAR,
	EVENT_SAVED,
	EVENT_SAVE_ERROR,
	EVENT_SAVE_RESULT_CLEAR,
	SET_SELECTED_EVENT_ID
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';

/**
 * Event Actions
 */

export const getEvents = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().events;
		if (state.isRequesting) {
			// console.log('getEvents: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getEvents(ids, options);
			};
			dispatch(queueDeferredAction('events', deferred));
			return;
		}
		dispatch(eventsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=events');
		} catch (err) {
			error = err;
		}

		dispatch(eventsRequestEnd());

		if (error) {
			return dispatch(eventsLoadError(error));
		}

		dispatch(eventsLoaded({ events: response?.data }));
	};
};

export const loadEvent = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadEvent, no id!');
			return dispatch(
				eventsLoadError({
					message: 'no id found to load event with'
				})
			);
		}
		console.log('loadEvent loadEvent loadEvent loadEvent ');
		console.log(id);
		const state = getState().events;
		if (state.isRequesting) {
			// console.log('getEvents: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadEvent(id);
			};
			dispatch(queueDeferredAction('events', deferred));
			return;
		}
		dispatch(eventsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/get?oType=events&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(eventsRequestEnd());

		if (error) {
			return dispatch(eventsLoadError(error));
		}

		console.log('loadEvent loadEvent loadEvent loadEvent ');
		console.log(response);

		dispatch(eventsLoaded({ events: [response?.data] }));
	};
};

export const setSelectedEventId = id => ({
	type: SET_SELECTED_EVENT_ID,
	payload: { id }
});

export const addEvent = data => {
	return async (dispatch, getState) => {
		const state = getState().events;
		if (state.isRequesting) {
			const deferred = () => {
				addEvent(data);
			};
			dispatch(queueDeferredAction('events', deferred));
			return;
		}
		dispatch(eventsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=events', { data });
		} catch (err) {
			error = err;
		}

		console.log('addEvent, response');
		console.log(response);

		dispatch(eventsRequestEnd());

		if (error) {
			dispatch(eventSaveError(responses.EVENT_SAVE_ERROR));
			return dispatch(eventsAddError(error));
		}

		dispatch(eventSaved(responses.EVENT_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addEventLocalRecord(data));
		}
	};
};

export const updateEvent = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateEvent, no id!');
			return dispatch(
				eventsUpdateError({
					message: 'no id found to update event with'
				})
			);
		}
		const state = getState().events;
		if (state.isRequesting) {
			const deferred = () => {
				updateEvent(id, data);
			};
			dispatch(queueDeferredAction('events', deferred));
			return;
		}
		dispatch(eventsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=events&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(eventsRequestEnd());

		console.log('updateEvent ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(eventSaveError(responses.EVENT_SAVE_ERROR));
			return dispatch(eventsUpdateError(error));
		}

		dispatch(eventSaved(responses.EVENT_SAVED));
		dispatch(updateEventLocalRecord(id, data));
	};
};

export const deleteEvent = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteEvent, no id!');
			return false; // dispatch(eventsUpdateError({ message: 'no id found to update event with' }));
		}
		const state = getState().events;
		if (state.isRequesting) {
			const deferred = () => {
				deleteEvent(id);
			};
			dispatch(queueDeferredAction('events', deferred));
			return;
		}
		dispatch(eventsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete('/api/delete?oType=events&id=' + id);
		} catch (err) {
			error = err;
		}

		dispatch(eventsRequestEnd());

		console.log('deleteEvent ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(eventDeleteError(responses.EVENT_DELETE_ERROR));
			// return dispatch(eventsUpdateError(error));
		}

		dispatch(eventDeleted(responses.EVENT_DELETED));
		dispatch(dropEventLocalRecord(id));
	};
};

export const addEventLocalRecord = data => ({
	type: EVENTS_ADD_LOCAL_RECORD,
	payload: { data }
});

export const updateEventLocalRecord = (id, data) => ({
	type: EVENTS_UPDATE_LOCAL_RECORD,
	payload: { id, data }
});

export const eventsLoaded = ({ events }) => ({
	type: EVENTS_LOADED,
	payload: { events }
});

export const eventSaved = responseMsg => ({
	type: EVENT_SAVED,
	payload: responseMsg
});

export const eventSaveError = responseMsg => ({
	type: EVENT_SAVE_ERROR,
	payload: responseMsg
});

export const dropEventLocalRecord = id => ({
	type: EVENTS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const eventDeleted = responseMsg => ({
	type: EVENT_DELETED,
	payload: responseMsg
});

export const eventDeleteError = responseMsg => ({
	type: EVENT_DELETE_ERROR,
	payload: responseMsg
});

export const eventClearSaveResult = () => ({
	type: EVENT_SAVE_RESULT_CLEAR
});

export const eventClearDeleteResult = () => ({
	type: EVENT_DELETE_RESULT_CLEAR
});

export const eventsLoadError = error => ({
	type: EVENTS_LOAD_ERROR,
	payload: { error }
});

export const eventsAddError = error => ({
	type: EVENTS_ADD_ERROR,
	payload: { error }
});

export const eventsUpdateError = error => ({
	type: EVENTS_UPDATE_ERROR,
	payload: { error }
});

export const eventsRequestEnd = () => ({
	type: EVENTS_REQUEST_END,
	payload: false
});

export const eventsRequestStart = () => ({
	type: EVENTS_REQUEST_START,
	payload: true
});
