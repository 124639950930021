export const ProductTypes = {
	Donation: 'donation',
	DonationRaffle: 'donation-raffle',
	DonationCustom: 'donation-custom', // not sure about this yet? "choose your own amount"
	EventTicket: 'event-ticket',
	EventAccommodation: 'event-accommodation'
};

export const productTypeOptions = Object.entries(ProductTypes).map(([name, value]) => ({
	name,
	value
}));
