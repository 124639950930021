import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from 'theme-ui';
import useSponsors from '../../../hooks/useSponsors';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';

const SponsorsView = props => {
	const { data, error } = props;
	const { selectedSponsorId, getSponsor } = useSponsors();

	console.log('SponsorsView');
	console.log(selectedSponsorId, data, error);

	useEffect(() => {
		if (!data && selectedSponsorId) {
			console.log('getSponsor!!!!' + selectedSponsorId);
			getSponsor(selectedSponsorId);
		}
	}, [data, selectedSponsorId, getSponsor]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading sponsor....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Sponsor:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Brand: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Contact: </td>
						<td>{data.contact}</td>
					</tr>
					<tr>
						<td>City / State: </td>
						<td>{data.city + ', ' + data.state}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
			{data.imageId && <ImageDisplayer id={data.imageId} />}
		</div>
	);
};

export default SponsorsView;
