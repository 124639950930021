/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Text } from 'theme-ui'

import useEvents from '../../../hooks/useEvents';

const ActionButtons = props => {
	const { method } = props;
	const { theme } = useThemeUI();

	const {
		// events,
		// currentEvent,
		selectedEventId,
		error
	} = useEvents();

	const linkSx = {
		border: '1px solid #000',
		borderRadius: '5px',
		p: '4px 8px',
		mr: '1em',
		fontSize: '0.85em',
		textTransform: 'uppercase',
		backgroundColor: theme.colors.muted,
		color: theme.colors.primary,
		display: 'inline-block',
		textDecoration: 'none'
	};

	return (
		<div
			sx={{
				mb: '1.5em'
			}}>
			{method !== 'list' && (
				<Link sx={linkSx} to='/data/events'>
					Back to Events List
				</Link>
			)}
			{['add', 'edit', 'delete'].indexOf(method) === -1 && (
				<Link sx={linkSx} to='/data/events/add'>
					+ Add a Event
				</Link>
			)}
			{method === 'view' && selectedEventId && (
				<>
					<Link
						sx={{
							...linkSx,
							color: theme.colors.text,
							fontWeight: 'bold'
						}}
						to={'/data/events/edit/' + selectedEventId}>
						Edit this Event
					</Link>
					<Link sx={linkSx} to={'/data/events/delete/' + selectedEventId}>
						-- DELETE Event --
					</Link>
				</>
			)}
		</div>
	);
};

export default ActionButtons;
