/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useEvents from '../../../hooks/useEvents';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { showCityState } from '../../../utils/datasource';
import { ListTable } from '../../ThemeUiStyled';

const EventsList = props => {
	const { data, error } = props;
	const {
		selectEvent,
		deleteEvent,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed,
		events,
		getEvents
	} = useEvents();
	const history = useHistory();

	useEffect(() => {
		if (!events?.length) {
			getEvents();
		}
	}, [events, getEvents]);

	const [eventToDelete, setEventToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>No data found, add something to the list.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting events....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectEvent(item._id);
		history.push('/data/events/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectEvent(item._id);
		history.push('/data/events/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setEventToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setEventToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		eventToDelete && deleteEvent(eventToDelete._id);
		setEventToDelete(null);
		setShowDeleteModal(false);
	};

	const displayItemDates = (item, parent) => {
		const display = [];
		if (item.useParentDates) {
			return displayItemDates(parent);
		} else {
			if (item.startDate) {
				display.push(item.startDate);
				if (item.endDate) {
					display.push(item.endDate);
				}
				return display.join(' - ');
			}
		}
		return '';
	};

	const displayItemVenue = (item, parent) => {
		if (item.useParentVenue) {
			return displayItemVenue(parent);
		} else {
			return (
				<span>
					{item.venueName}
					<br />
					{showCityState(item)}
				</span>
			);
		}
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '2px 5px',
		fontSize: '12px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='event'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Event</td>
						<td>Parent Event</td>
						<td>Start/End</td>
						<td>Venue</td>
						<td>Enabled</td>
						<td>Actions</td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						const parentEvent = events.find(event => event._id === item.parentEvent);
						return (
							<tr key={idx}>
								<td style={{ fontSize: '13px' }}>{item.label}</td>
								<td style={{ fontSize: '11px' }}>{parentEvent?.label || '-'}</td>
								<td style={{ fontSize: '11px' }}>
									{displayItemDates(item, parentEvent)}
								</td>
								<td style={{ fontSize: '11px' }}>
									{displayItemVenue(item, parentEvent)}
								</td>
								<td>{item.enabled && 'x'}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default EventsList;
