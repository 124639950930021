import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner } from 'theme-ui';
import useEvents from '../../../hooks/useEvents';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import AddressFields from '../../segments/AddressFields';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import SelectOne from '../../segments/SelectOne';
import DataAreaList from '../../segments/DataAreaList';
import DatePicker from '../../segments/DatePicker';

const EventsAdd = props => {
	const { data, error } = props;

	const {
		isProcessing,
		saveEvent,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed,
		getEvents,
		events
	} = useEvents();
	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	const eventParentOptions = useMemo(() => {
		if (events?.length) {
			return events
				.filter(e => e.enabled && e.canContainOtherEvents && e._id !== localData?._id)
				.map(group => ({
					name: group.label,
					value: group._id
				}));
		}

		return [];
	}, [events]);

	const myParentEvent = localData && events?.find(eg => eg._id === localData.parentEvent);

	useEffect(() => {
		if (!events?.length) {
			getEvents();
		}
	}, [getEvents, events]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveEvent(null, localData);
	};

	if (error) {
		return (
			<p>
				Oops, error!
				<br />
				{error}
			</p>
		);
	}

	return (
		<div>
			<h2>Add a New Event:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='label'>Event Name</Label>
							<Input
								name='label'
								mb={3}
								value={localData?.label || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='shortDescription'>Short Description</Label>
							<Input
								name='shortDescription'
								mb={3}
								value={localData?.shortDescription || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='shortBlurb'>Short Blurb</Label>
							<Input
								name='shortBlurb'
								mb={3}
								value={localData?.shortBlurb || ''}
								onChange={onFieldUpdate}
							/>

							<h3>Optional start / end dates...</h3>
							{myParentEvent && myParentEvent.startDate && (
								<Label mb={3} htmlFor='useParentDates'>
									<input
										type='checkbox'
										onChange={onFieldUpdate}
										name='useParentDates'
										value={localData?.useParentDates || false}
										checked={localData?.useParentDates || false}
									/>
									Use Parent Info for start / end dates
								</Label>
							)}

							{localData?.useParentDates && myParentEvent?.startDate && (
								<p>
									Dates: {moment(myParentEvent.startDate).format('YYYY-MM-DD')}
									{myParentEvent.endDate
										? ` to ${moment(myParentEvent.endDate).format(
												'YYYY-MM-DD'
										  )}`
										: ''}
								</p>
							)}

							{!localData?.useParentDates && (
								<Flex style={{ gap: 12 }}>
									<Box>
										<Label htmlFor='startDate'>Start Date</Label>
										<DatePicker
											value={localData?.startDate}
											name='startDate'
											onFieldUpdate={onFieldUpdate}
										/>
									</Box>
									<Box>
										<Label htmlFor='endDate'>End Date</Label>
										<DatePicker
											value={localData?.endDate}
											name='endDate'
											onFieldUpdate={onFieldUpdate}
										/>
										<p style={{ fontSize: '10px' }}>
											<em>(leave blank if single day event)</em>
										</p>
									</Box>
								</Flex>
							)}

							<h3>Optional venue / address...</h3>

							{myParentEvent && myParentEvent.venueName && (
								<Label mb={3} htmlFor='useParentVenue'>
									<input
										type='checkbox'
										onChange={onFieldUpdate}
										name='useParentVenue'
										value={localData?.useParentVenue || false}
										checked={localData?.useParentVenue || false}
									/>
									Use Parent Info for venue and location
								</Label>
							)}

							{!localData?.useParentVenue && (
								<>
									<Label htmlFor='venueName'>Venue</Label>
									<Input
										name='venueName'
										mb={3}
										value={localData?.venueName || ''}
										onChange={onFieldUpdate}
									/>
									<AddressFields onFieldUpdate={onFieldUpdate} data={localData} />
								</>
							)}

							{localData?.useParentVenue && myParentEvent?.venueName && (
								<p>Venue: {myParentEvent.venueName}</p>
							)}

							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Event'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>App Configuration, don't change these...</h3>

							<Flex style={{ gap: 12 }}>
								<Box sx={{ flex: 1 }}>
									<Label htmlFor='parentEvent'>Event Parent</Label>
									<SelectOne
										data={eventParentOptions}
										value={localData?.parentEvent}
										dataValueProp='value'
										name='parentEvent'
										onFieldUpdate={onFieldUpdate}
										noDataMsg='No container events found to choose from.'
									/>
									<p style={{ fontSize: '10px' }}>
										<em>
											Set this to the main event if this event belongs to
											another event
										</em>
									</p>
								</Box>
								<Box sx={{ flex: 1 }}>
									<Label htmlFor='tags'>Tags (one per line)</Label>
									<DataAreaList
										localData={localData}
										setLocalData={setLocalData}
										field='tags'
									/>
									{myParentEvent?.tags?.length && (
										<p style={{ fontSize: '12px' }}>
											<strong>
												Tags from the parent event (will be included):
											</strong>
											<br />
											{myParentEvent.tags.map((tag, idx) => {
												return (
													<em
														key={idx}
														sx={{
															display: 'block',
															mb: 0,
															fontSize: '10px'
														}}>
														{tag}
													</em>
												);
											})}
										</p>
									)}
								</Box>
							</Flex>

							<Flex sx={{ gap: 20 }}>
								<Box>
									<Label mb={3} htmlFor='enabled'>
										<input
											type='checkbox'
											onChange={onFieldUpdate}
											name='enabled'
											value={localData?.enabled || false}
											checked={localData?.enabled || false}
										/>
										Enabled
									</Label>
								</Box>
								<Box>
									<Label mb={3} htmlFor='canContainOtherEvents'>
										<input
											type='checkbox'
											onChange={onFieldUpdate}
											name='canContainOtherEvents'
											value={localData?.canContainOtherEvents || false}
											checked={localData?.canContainOtherEvents || false}
										/>
										Can Contain Other Events
									</Label>
								</Box>
							</Flex>

							<Label htmlFor='sortOrder'>Sort Order</Label>
							<Input
								name='sortOrder'
								mb={3}
								value={localData?.sortOrder || '0'}
								onChange={onFieldUpdate}
							/>

							{localData?.tags?.includes('ticketed-event') ? (
								<div>
									<Flex style={{ gap: 12 }}>
										<Box>
											<Label htmlFor='numTicketsAvailable'>
												Num Tickets Available
											</Label>
											<Input
												name='numTicketsAvailable'
												mb={3}
												value={localData?.numTicketsAvailable || ''}
												onChange={onFieldUpdate}
											/>
										</Box>
										<Box>
											<Label htmlFor='endDate'>Pre-Sale End Date</Label>
											<DatePicker
												value={localData?.endDate}
												name='endDate'
												onFieldUpdate={onFieldUpdate}
											/>
											<p style={{ fontSize: '10px' }}>
												<em>(leave blank if no end)</em>
											</p>
										</Box>
									</Flex>
								</div>
							) : null}

							<h3>Event Photo:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default EventsAdd;
