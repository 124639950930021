/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';

const purchaseEmailGenerator = (items, myStuffItems, products, events) => {
	const itemsWithQuantity = items?.filter(item => item.quantity);

	if (!itemsWithQuantity?.length) {
		return (
			<div>
				<em>
					No products selected yet (or maybe none with quantity picked more than zero),
					pick some products to see the email body that will get generated.
				</em>
			</div>
		);
	}

	const requiredOneOf = new Set();
	const requiredAnd = new Set();

	let oneOfRequiredMessage = '';
	let andRequiredMessage = '';

	const productsPurchasedInfo = itemsWithQuantity.map((item, idx) => {
		const myProduct = products.find(p => p._id === item.productId);
		const myEvent = myProduct && events.find(e => e._id === myProduct.eventId);

		if (!myProduct.emailBaseMessage) return;

		let quantityMessage = myProduct.emailPurchasedQuantityParser
			? myProduct.emailPurchasedQuantityParser.replace('{count}', item.quantity)
			: '';
		if (quantityMessage && item.quantity > 1) {
			quantityMessage += 's';
		}

		const baseMessage = myProduct.emailBaseMessage.replace('{quantity}', quantityMessage);

		const baseMessageSections = baseMessage.split('\n').filter(Boolean);

		if (myProduct.requiredProducts?.length && myProduct.emailRequiredProductsMessage) {
			myProduct.requiredProducts.forEach(id => {
				if (myProduct.requiredProductsRule === 'one-of') {
					requiredOneOf.add(id);
					oneOfRequiredMessage = myProduct.emailRequiredProductsMessage;
				} else {
					requiredAnd.add(id);
					andRequiredMessage = myProduct.emailRequiredProductsMessage;
				}
			});
		}

		return (
			<div key={idx}>
				<h3>{myProduct.label}</h3>
				{baseMessageSections.map((msg, idx) => (
					<p key={idx}>{msg}</p>
				))}
			</div>
		);
	});

	let requiredOneOfPurchasesInfo = '';

	if (requiredOneOf.size && oneOfRequiredMessage) {
		const requiredProducts = [...requiredOneOf];
		const missingRequiredProducts = requiredProducts.filter(id => {
			console.log(
				'trying to find required ',
				id,
				'in itemsWithQ ',
				itemsWithQuantity.map(item => item.productId),
				!itemsWithQuantity.find(item => item.productId === id)
			);
			return (
				!itemsWithQuantity.find(item => item.productId === id) &&
				!myStuffItems.find(item => item.productId === id)
			);
		});

		console.log('ok handle requiredOneOf', {
			requiredProducts,
			missingRequiredProducts,
			itemsWithQuantity
		});

		if (
			missingRequiredProducts.length &&
			missingRequiredProducts.length === requiredProducts.length
		) {
			const myRequiredProducts = products.filter(p =>
				missingRequiredProducts.includes(p._id)
			);
			const lastRequiredProduct = myRequiredProducts.pop();
			const requiredProductList =
				'one of ' +
				myRequiredProducts.map(p => p.label).join(', ') +
				' or ' +
				lastRequiredProduct.label;

			requiredOneOfPurchasesInfo = oneOfRequiredMessage.replace(
				'{requiredProducts}',
				requiredProductList
			);
		} else {
			console.log('fail, did not find the required list', { requiredOneOf });
		}
	} else {
		console.log('fail, no requireds', { requiredOneOf, oneOfRequiredMessage });
	}

	return (
		<div>
			{productsPurchasedInfo}
			{requiredOneOfPurchasesInfo ? (
				<div>
					<h3>Don't forget...</h3>
					<p>{requiredOneOfPurchasesInfo}</p>
				</div>
			) : null}
		</div>
	);
};

export default purchaseEmailGenerator;
