/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useMemo } from 'react';
import { Textarea } from 'theme-ui';

const DataAreaList = props => {
	const { setLocalData, localData, field, _sx } = props;

	const handleChange = e => {
		const taValue = e.target.value;
		if (taValue) {
			const items = taValue.split('\n').map(line => line.trim());
			const data = {
				...localData,
				[field]: items
			};
			setLocalData(data);
		} else {
			setLocalData({
				...localData,
				[field]: []
			});
		}
	};

	if (localData?.[field] && !(localData?.[field] instanceof Array)) {
		return <p>data type error, the {field} is not an array of things</p>;
	}

	return (
		<Textarea
			name='tags'
			mb={3}
			value={localData?.[field]?.join('\n') || ''}
			rows={4}
			onChange={handleChange}
		/>
	);
};

// DataAreaList.defaultProps = {
// useEmptySelector: true,
// dataLabelProp: 'name',
// dataValueProp: '_id',
// emptySelctorMsg: 'select one',
// noDataMsg: 'no options'
// };

export default DataAreaList;
