import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useAdmins from '../../../hooks/useAdmins';
import { ViewTable } from '../../ThemeUiStyled';

const AdminsView = props => {
	const { data, error } = props;
	const { selectedAdminId, getAdmin } = useAdmins();

	console.log('AdminsView');
	console.log(selectedAdminId, data, error);

	useEffect(() => {
		if (!data && selectedAdminId) {
			console.log('getAdmin!!!!' + selectedAdminId);
			getAdmin(selectedAdminId);
		}
	}, [data, selectedAdminId, getAdmin]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading admin....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Admin:</h2>

			<ViewTable>
				<tbody>
					<tr>
						<td>Name: </td>
						<td>{data.name}</td>
					</tr>
					<tr>
						<td>Email: </td>
						<td>{data.email}</td>
					</tr>
					<tr>
						<td>Phone: </td>
						<td>{data.phone}</td>
					</tr>
				</tbody>
				<colgroup>
					<col style={{ width: '120px' }}></col>
					<col style={{ width: '320px' }}></col>
				</colgroup>
			</ViewTable>
		</div>
	);
};

export default AdminsView;
