import React, { useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import { Box, Button, Flex, Input, Label, Spinner, Textarea } from 'theme-ui';
import useProducts from '../../../hooks/useProducts';
import useEvents from '../../../hooks/useEvents';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
import SelectOne from '../../segments/SelectOne';
import SelectOneList from '../../segments/SelectOneList';
import DataAreaList from '../../segments/DataAreaList';
import DatePicker from '../../segments/DatePicker';
import { ProductTypes, productTypeOptions } from './constants';

const ProductsAdd = props => {
	const { data, error } = props;

	const { getEvents, events } = useEvents();

	const {
		isProcessing,
		saveProduct,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed,
		getProducts,
		products,
		unpackExtras
	} = useProducts();

	const [localData, setLocalData] = useState(data);
	const [localSaveResult, setLocalSaveResult] = useState(null);

	const productOptions = useMemo(() => {
		if (products) {
			return products.filter(p => p.enabled && p._id !== localData?._id);
		}
		return [];
	}, [products, localData]);

	const eventOptions = useMemo(() => {
		if (events) {
			return events.map(event => ({
				name: event.label,
				value: event._id
			}));
		}

		return [];
	}, [events]);

	const myEvent = localData && events?.find(eg => eg._id === localData.eventId);

	useEffect(() => {
		if (!events?.length) {
			getEvents();
		}
	}, [getEvents, events]);

	useEffect(() => {
		if (!products?.length) {
			getProducts();
		}
	}, [getProducts, products]);

	useEffect(() => {
		if (!localData && data) {
			setLocalData(unpackExtras(data));
		}
	}, [localData, data, setLocalData]);

	useEffect(() => {
		if (!localSaveResult?.status) {
			setLocalSaveResult(uiStatusMessageSaveResult);
		}
	}, [localSaveResult, setLocalSaveResult, uiStatusMessageSaveResult]);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	const handleForm = e => {
		e.preventDefault();
		saveProduct(null, localData);
	};

	if (error) {
		return (
			<p>
				Oops, error!
				<br />
				{error}
			</p>
		);
	}

	return (
		<div>
			<h2>Add a New Product:</h2>
			<EditorContainer
				_sx={{
					width: ['100%', '100%', '90%']
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='label'>Product Name</Label>
							<Input
								name='label'
								mb={3}
								value={localData?.label || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='shortDescription'>Short Description</Label>
							<Input
								name='shortDescription'
								mb={3}
								value={localData?.shortDescription || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='shortBlurb'>Short Blurb</Label>
							<Input
								name='shortBlurb'
								mb={3}
								value={localData?.shortBlurb || ''}
								onChange={onFieldUpdate}
							/>

							<h3>Email message config</h3>

							<Label htmlFor='emailBaseMessage'>Base Message</Label>
							<Textarea
								rows={4}
								name='emailBaseMessage'
								mb={1}
								value={localData?.emailBaseMessage || ''}
								onChange={onFieldUpdate}
								placeholder={
									'EXAMPLE: Thanks for your purchase of {quantity} to win the Nissan.'
								}
							/>
							<p style={{ fontSize: '10px', marginBottom: '6px' }}>
								Make sure you put this tag in the text where the count of items will
								go:
								<br />
								<strong>{`{quantity}`}</strong>
							</p>

							<Label htmlFor='emailPurchasedQuantityParser'>Quantity Parser</Label>
							<Input
								name='emailPurchasedQuantityParser'
								mb={1}
								value={localData?.emailCountParser || ''}
								onChange={onFieldUpdate}
								placeholder={'EXAMPLE: {count} Golf Swing'}
							/>
							<p style={{ fontSize: '10px', marginBottom: '6px' }}>
								This will be used to build the quantity message, leave singular.
								<br />
								The generator will pluralize as necessary.
							</p>

							{localData?.requiredProducts?.length && (
								<>
									<Label htmlFor='emailRequiredProductsMessage'>
										Required Products Message
									</Label>
									<Textarea
										rows={4}
										name='emailRequiredProductsMessage'
										mb={3}
										value={localData?.emailRequiredProductsMessage || ''}
										onChange={onFieldUpdate}
									/>
									<p style={{ fontSize: '10px' }}>
										Make sure you put this tag in the text where the product(s)
										name will go:
										<br />
										<strong>{`{requiredProducts}`}</strong>
									</p>
								</>
							)}

							<EditorSaveButton
								status={localSaveResult?.status}
								isProcessing={isProcessing}
								label='Product'
								uiStatusSaveMessageConsumed={uiStatusSaveMessageConsumed}
								setLocalData={setLocalData}
								setLocalSaveResult={setLocalSaveResult}
							/>
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<h3>Product Photo:</h3>
							<ImageUploader
								id={getLocalImageId(localData)}
								onChange={updateImageData}
							/>

							<h3>App Configuration, don't change these...</h3>

							<Flex style={{ gap: 12 }}>
								<Box style={{ flex: 1 }}>
									<Label mb={3} htmlFor='enabled'>
										<input
											type='checkbox'
											onChange={onFieldUpdate}
											name='enabled'
											value={localData?.enabled || false}
											checked={localData?.enabled || false}
										/>
										Enabled
									</Label>
								</Box>
							</Flex>

							<Flex style={{ gap: 12 }}>
								<Box>
									<Label htmlFor='sortOrder'>Sort Order</Label>
									<Input
										name='sortOrder'
										mb={3}
										value={localData?.sortOrder || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
								<Box>
									<Label htmlFor='sortOrder'>Price in cents</Label>
									<Input
										name='priceCents'
										mb={3}
										value={localData?.priceCents || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
								<Box>
									<Label htmlFor='maxPerSession'>Max Per Cart</Label>
									<Input
										name='maxPerSession'
										mb={3}
										value={localData?.maxPerSession || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>

							<Flex sx={{ gap: 12 }}>
								<Box sx={{ flex: '0.5' }}>
									<Label htmlFor='pType'>Product Type</Label>
									<SelectOne
										data={productTypeOptions}
										value={localData?.pType}
										dataValueProp='value'
										name='pType'
										onFieldUpdate={onFieldUpdate}
										noDataMsg='No product types found to choose from.'
									/>
								</Box>
								<Box sx={{ flex: 1 }}>
									<Label htmlFor='tags'>Tags (one per line)</Label>
									<DataAreaList
										localData={localData}
										setLocalData={setLocalData}
										field='tags'
									/>
									{myEvent?.tags?.length && (
										<p style={{ fontSize: '12px' }}>
											<strong>
												Tags from the linked event (will be included):
											</strong>
											<br />
											{myEvent.tags.map((tag, idx) => {
												return (
													<em
														key={idx}
														sx={{
															display: 'block',
															mb: 0,
															fontSize: '10px'
														}}>
														{tag}
													</em>
												);
											})}
										</p>
									)}
								</Box>
							</Flex>

							{(localData?.pType === ProductTypes.EventTicket ||
								localData?.pType === ProductTypes.EventAccommodation) && (
								<>
									<Label htmlFor='pType'>Event</Label>
									<SelectOne
										data={eventOptions}
										value={localData?.eventId}
										dataValueProp='value'
										name='eventId'
										onFieldUpdate={onFieldUpdate}
										noDataMsg='No product types found to choose from.'
									/>
								</>
							)}

							{(localData?.pType === ProductTypes.EventTicket ||
								localData?.pType === ProductTypes.EventAccommodation) && (
								<div>
									{myEvent && myEvent.numTicketsAvailable ? (
										<p>
											Linked event tickets available:{' '}
											{myEvent.numTicketsAvailable}
										</p>
									) : (
										<Flex style={{ gap: 12 }}>
											<Box>
												<Label htmlFor='numTicketsAvailable'>
													Num Tickets Available
												</Label>
												<Input
													name='numTicketsAvailable'
													mb={3}
													value={localData?.numTicketsAvailable || ''}
													onChange={onFieldUpdate}
												/>
											</Box>
											<Box>
												<Label htmlFor='endDate'>Pre-Sale End Date</Label>
												<DatePicker
													value={localData?.endDate}
													name='endDate'
													onFieldUpdate={onFieldUpdate}
												/>
												<p style={{ fontSize: '10px' }}>
													<em>(leave blank if no end)</em>
												</p>
											</Box>
										</Flex>
									)}
								</div>
							)}
							<hr />
							<p style={{ fontSize: '12px' }}>
								If this product requires other products, add them here.
								<br />
								<em>
									Use case: Closest to the Pin also needs a Day Pass or Multi-Day
									Pass.
								</em>
							</p>

							<Flex sx={{ gap: 12 }}>
								<Box sx={{ flex: 0.5 }}>
									<Label mb={3} htmlFor='requiredProductsRule'>
										<span>
											<input
												type='radio'
												onChange={onFieldUpdate}
												name='requiredProductsRule'
												value={'all'}
												checked={localData?.requiredProductsRule === 'all'}
											/>
											All
										</span>
										<span>
											<input
												type='radio'
												onChange={onFieldUpdate}
												name='requiredProductsRule'
												value={'one-of'}
												checked={
													localData?.requiredProductsRule === 'one-of'
												}
											/>
											One of
										</span>
									</Label>
								</Box>
								<Flex sx={{ flexDirection: 'column', flex: 1 }}>
									<Label htmlFor='entity'>Pick Required Products</Label>
									<SelectOneList
										data={productOptions}
										value={localData?.requiredProducts}
										dataLabelProp='label'
										dataValueProp='_id'
										name='requiredProducts'
										onFieldUpdate={onFieldUpdate}
										noDataMsg='No other products found to choose from.'
									/>
								</Flex>
							</Flex>

							<hr />

							<h3>Extras:</h3>

							<Flex style={{ gap: 12 }}>
								<Box>
									<Label htmlFor='raffles'>Raffle Tickets</Label>
									<Input
										name='raffles'
										mb={3}
										value={localData?.raffles || '0'}
										onChange={onFieldUpdate}
									/>
								</Box>
								<Box>
									<Label htmlFor='jackpot'>GHA Jackpot Tickets</Label>
									<Input
										name='jackpot'
										mb={3}
										value={localData?.jackpot || '0'}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default ProductsAdd;
