/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Flex, Box, Button, Input, Label, Spinner } from 'theme-ui';
import useVoteContests from '../../../hooks/useVoteContests';
import useLocations from '../../../hooks/useLocations';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { EditorContainer } from '../../ThemeUiStyled';
import EditorSaveButton from '../../segments/EditorSaveButton';
import SelectOne from '../../segments/SelectOne';
import DatePicker from '../../segments/DatePicker';
import ImageUploader from '../../segments/ImageUploader';
import { getLocalImageId, getOnFieldUpdate, getUpdateImageData } from '../../../utils/datasource';
// import { objectSort } from '../../../utils';
import { voteContestTypeOptions } from 'constants/eventUiHelpers';
import VoteContestSetup from 'components/tools/EventBuilders/SetupBuilders/VoteContestSetup';
import VoteGroupsSetup from 'components/tools/EventBuilders/SetupBuilders/VoteGroupsSetup';

export const voteContestCategoryTypeOptions = [
	{ name: 'All Candidates', _id: 'ALL' },
	{ name: 'Selected', _id: 'SELECTED' }
];

const VoteContestsEdit = props => {
	const { data, error } = props;
	const {
		selectedVoteContestId,
		getVoteContest,
		saveVoteContest,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useVoteContests();

	const {
		getLocations,
		locations,
		error: locationError,
		isProcessing: isProcessingLocations
	} = useLocations();

	const [localData, setLocalData] = useState(data);
	const [hasRequestedLocations, setHasRequestedLocations] = useState(false);

	const onFieldUpdate = getOnFieldUpdate(setLocalData, localData);
	const updateImageData = getUpdateImageData(setLocalData, localData);

	useEffect(() => {
		if (!hasRequestedLocations) {
			setHasRequestedLocations(true);
			getLocations();
		}
	}, [hasRequestedLocations, getLocations, setHasRequestedLocations]);

	useEffect(() => {
		if (!data && selectedVoteContestId) {
			getVoteContest(selectedVoteContestId);
		} else if (data?._id !== selectedVoteContestId) {
			getVoteContest(selectedVoteContestId);
		}
	}, [data, selectedVoteContestId, getVoteContest]);

	const parseVoteContestDate = dateStamp => {
		if (/\d{4}\-\d{2}\-\d{2}/.test(dateStamp)) {
			const voteContestDate = new Date();
			const voteContestParts = dateStamp.split('-');
			voteContestDate.setFullYear(voteContestParts[0]);
			voteContestDate.setMonth(voteContestParts[1] - 1);
			voteContestDate.setDate(voteContestParts[2]);
			return voteContestDate;
		}
		throw new Error('bad date format, cannot continue');
	};

	useEffect(() => {
		if (!localData && data) {
			// set up voteContest date for month picker
			// const voteContestDate = new Date();
			// const voteContestParts = data.voteContestDate.split('-');
			// voteContestDate.setFullYear(voteContestParts[0]);
			// voteContestDate.setMonth(voteContestParts[1] - 1);
			// voteContestDate.setDate(voteContestParts[2]);
			// data.voteContestDate = voteContestDate;
			data.voteContestDate = parseVoteContestDate(data.voteContestDate);
			setLocalData(data);
		}
	}, [localData, data, setLocalData]);

	const handleForm = e => {
		e.preventDefault();
		// console.log('save voteContest!!!!');
		// console.log(localData);
		// const voteContestDate = new Date(localData.voteContestDate);
		// const timeParts = localData.time?.split(':') || ['19', '00']; // default voteContest time is 19 hrs GMT
		// voteContestDate.setHours(timeParts[0]);
		// voteContestDate.setMinutes(timeParts[1]);
		const saveData = { ...localData };
		const voteContestDate = moment(localData.voteContestDate);
		saveData.voteContestDate = voteContestDate.format('YYYY-MM-DD');
		saveVoteContest(selectedVoteContestId, saveData);
		localData.voteContestDate = parseVoteContestDate(saveData.voteContestDate);
		setLocalData(localData);
	};

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading voteContest....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Edit VoteContest:</h2>
			<EditorContainer
				_sx={{
					width: '100%'
				}}>
				<Box as='form' onSubmit={handleForm}>
					<Flex>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px'
							}}>
							<Label htmlFor='name'>Vote Contest</Label>
							<Input
								name='name'
								mb={3}
								value={localData?.name || ''}
								onChange={onFieldUpdate}
							/>
							<Label htmlFor='location'>Location</Label>
							<SelectOne
								data={locations}
								value={localData?.location}
								name='location'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No locations found to choose from.'
							/>
							<Label htmlFor='voteContestType'>Vote Contest Type</Label>
							<SelectOne
								data={voteContestTypeOptions}
								value={localData?.voteContestType}
								name='voteContestType'
								onFieldUpdate={onFieldUpdate}
								noDataMsg='No voteContest types found to choose from.'
							/>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteContestDate'>Date</Label>
									<DatePicker
										value={localData?.voteContestDate}
										name='voteContestDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto'
									}}>
									<Label htmlFor='time'>Time (GMT/24hr)</Label>
									<Input
										name='time'
										mb={3}
										value={localData?.time || ''}
										onChange={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<Flex sx={{ mt: '10px' }}>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteStartDate'>Voting Starts Date</Label>
									<DatePicker
										value={localData?.voteStartDate}
										name='voteStartDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
								<Box
									sx={{
										flex: '1 1 auto',
										mr: '10px'
									}}>
									<Label htmlFor='voteEndDate'>Voting Ends Date</Label>
									<DatePicker
										value={localData?.voteEndDate}
										name='voteEndDate'
										onFieldUpdate={onFieldUpdate}
									/>
								</Box>
							</Flex>
							<EditorSaveButton isProcessing={isProcessing} label='VoteContest' />
							<UiStatusMessageDisplay
								message={uiStatusMessageSaveResult}
								consumed={uiStatusSaveMessageConsumed}
							/>
						</Box>
						<Box
							sx={{
								flex: '1 1 auto',
								width: '50%',
								mx: '10px',
								paddingLeft: '10px',
								borderLeft: '1px solid white'
							}}>
							<h3>Set Vote Contest Logo / Image</h3>
							<Box
								sx={{
									flex: '1 1 auto',
									width: '50%',
									mx: '10px'
								}}>
								<ImageUploader
									id={getLocalImageId(localData)}
									onChange={updateImageData}
								/>
							</Box>
							{/*
							<h3>Set up Candidate Groups</h3>
							// TODO: add VoteGroupsSetup for managing
							// groups / segments within a Vote Contest
							*/}

							<h3>Set up Voting Categories</h3>
							<VoteContestSetup localData={localData} onFieldUpdate={onFieldUpdate} />
						</Box>
					</Flex>
				</Box>
			</EditorContainer>
		</div>
	);
};

export default VoteContestsEdit;
