import axios from 'axios';
import { queueDeferredAction } from '.';
import {
	COMMS_ADD_ERROR,
	COMMS_LOADED,
	COMMS_LOAD_ERROR,
	COMMS_REQUEST_END,
	COMMS_REQUEST_START,
	COMMS_UPDATE_ERROR,
	COMMS_ADD_LOCAL_RECORD,
	COMMS_UPDATE_LOCAL_RECORD,
	COMMS_DROP_LOCAL_RECORD,
	EMAIL_TEMPLATE_DELETED,
	EMAIL_TEMPLATE_DELETE_ERROR,
	EMAIL_TEMPLATE_DELETE_RESULT_CLEAR,
	EMAIL_TEMPLATE_SAVED,
	EMAIL_TEMPLATE_SAVE_ERROR,
	EMAIL_TEMPLATE_SAVE_RESULT_CLEAR,
	SET_SELECTED_EMAIL_TEMPLATE_ID,
	SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE,
	SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR
} from './actionTypes';

import * as responses from '../../constants/reduxResponses';
import { COMMS_TYPES } from '../../constants';

/**
 * EmailTemplate Actions
 */

export const getEmailTemplates = (ids = [], options = {}) => {
	return async (dispatch, getState) => {
		const state = getState().comms;
		if (state.isRequesting) {
			// console.log('getEmailTemplates: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				getEmailTemplates(ids, options);
			};
			dispatch(queueDeferredAction('comms', deferred));
			return;
		}
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get('/api/list?oType=comms&type=' + COMMS_TYPES.EMAIL_TEMPLATE);
		} catch (err) {
			error = err;
		}

		dispatch(commsRequestEnd());

		if (error) {
			return dispatch(commsLoadError(error));
		}

		dispatch(emailTemplatesLoaded({ templates: response?.data }));
	};
};

export const loadEmailTemplate = ({ id }, options = {}) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('loadEmailTemplate, no id!');
			return dispatch(commsLoadError({ message: 'no id found to load user with' }));
		}
		console.log('loadEmailTemplate loadEmailTemplate loadEmailTemplate loadEmailTemplate ');
		console.log(id);
		const state = getState().comms;
		if (state.isRequesting) {
			// console.log('getEmailTemplates: state.isRequesting, do not start another request');
			// console.log('CONSIDER: queue this?');
			const deferred = () => {
				loadEmailTemplate(id);
			};
			dispatch(queueDeferredAction('comms', deferred));
			return;
		}
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.get(
				'/api/get?oType=comms&type=' + COMMS_TYPES.EMAIL_TEMPLATE + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(commsRequestEnd());

		if (error) {
			return dispatch(commsLoadError(error));
		}

		console.log('loadEmailTemplate loadEmailTemplate loadEmailTemplate loadEmailTemplate ');
		console.log(response);

		dispatch(emailTemplatesLoaded({ templates: [response?.data] }));
	};
};

export const setSelectedEmailTemplateId = id => ({
	type: SET_SELECTED_EMAIL_TEMPLATE_ID,
	payload: { id }
});

export const addEmailTemplate = data => {
	return async (dispatch, getState) => {
		const state = getState().comms;
		if (state.isRequesting) {
			const deferred = () => {
				addEmailTemplate(data);
			};
			dispatch(queueDeferredAction('comms', deferred));
			return;
		}
		dispatch(commsRequestStart());
		data.type = COMMS_TYPES.EMAIL_TEMPLATE;
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=comms', { data });
		} catch (err) {
			error = err;
		}

		console.log('addEmailTemplate, response');
		console.log(response);

		dispatch(commsRequestEnd());

		if (error) {
			dispatch(emailTemplateSaveError(responses.EMAIL_TEMPLATE_SAVE_ERROR));
			return dispatch(commsAddError(error));
		}

		dispatch(emailTemplateSaved(responses.EMAIL_TEMPLATE_SAVED));
		data._id = response?.data?._id;
		if (data._id) {
			dispatch(addEmailTemplateLocalRecord(data));
		}
	};
};

export const updateEmailTemplate = (id, data) => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('updateEmailTemplate, no id!');
			return dispatch(
				commsUpdateError({
					message: 'no id found to update user with'
				})
			);
		}
		const state = getState().comms;
		if (state.isRequesting) {
			const deferred = () => {
				updateEmailTemplate(id, data);
			};
			dispatch(queueDeferredAction('comms', deferred));
			return;
		}
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/api/save?oType=comms&id=' + id, {
				data
			});
		} catch (err) {
			error = err;
		}

		dispatch(commsRequestEnd());

		console.log('updateEmailTemplate ::::: response, error');
		console.log(response, error);

		if (error) {
			dispatch(emailTemplateSaveError(responses.EMAIL_TEMPLATE_SAVE_ERROR));
			return dispatch(commsUpdateError(error));
		}

		dispatch(emailTemplateSaved(responses.EMAIL_TEMPLATE_SAVED));
		dispatch(updateEmailTemplateLocalRecord(id, data));
	};
};

export const deleteEmailTemplate = id => {
	return async (dispatch, getState) => {
		if (!id) {
			console.error('deleteEmailTemplate, no id!');
			return false; // dispatch(commsUpdateError({ message: 'no id found to update user with' }));
		}
		const state = getState().comms;
		if (state.isRequesting) {
			const deferred = () => {
				deleteEmailTemplate(id);
			};
			dispatch(queueDeferredAction('comms', deferred));
			return;
		}
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.delete(
				'/api/delete?oType=comms&type=' + COMMS_TYPES.EMAIL_TEMPLATE + '&id=' + id
			);
		} catch (err) {
			error = err;
		}

		dispatch(commsRequestEnd());

		console.log('deleteEmailTemplate ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(emailTemplateDeleteError(responses.EMAIL_TEMPLATE_DELETE_ERROR));
			// return dispatch(commsUpdateError(error));
		}

		dispatch(emailTemplateDeleted(responses.EMAIL_TEMPLATE_DELETED));
		dispatch(dropEmailTemplateLocalRecord(id));
	};
};

export const sendCustomEmailMessage = (user, message, subject) => {
	return async (dispatch, getState) => {
		if (!user || !message) {
			console.error('sendCustomEmailMessage, missing required params');
			return false; // dispatch(commsUpdateError({ message: 'no id found to update user with' }));
		}
		const state = getState().comms;
		// if (state.isRequesting) {
		// 	const deferred = () => {
		// 		sendCustomEmailMessage(user, message);
		// 	};
		// 	dispatch(queueDeferredAction('comms', deferred));
		// 	return;
		// }
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/comms/email/send?oType=sendCustomMessage', {
				user,
				message,
				subject
			});
		} catch (err) {
			error = err;
		}

		// dispatch(commsRequestEnd());

		console.log('sendCustomEmailMessage ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(emailMessageSendError(error));
		}

		dispatch(emailMessageSendResponse(response));
	};
};

export const sendStaticTemplateEmailMessage = (user, staticTemplate, templateData) => {
	return async (dispatch, getState) => {
		if (!user || !staticTemplate) {
			console.error('sendStaticTemplateEmailMessage, missing required params');
			return false; // dispatch(commsUpdateError({ message: 'no id found to update user with' }));
		}
		const state = getState().comms;
		// if (state.isRequesting) {
		// 	const deferred = () => {
		// 		sendCustomEmailMessage(user, message);
		// 	};
		// 	dispatch(queueDeferredAction('comms', deferred));
		// 	return;
		// }
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/comms/email/send?oType=sendStaticTemplate', {
				user,
				staticTemplate,
				templateData
			});
		} catch (err) {
			error = err;
		}

		// dispatch(commsRequestEnd());

		console.log('sendStaticTemplateEmailMessage ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(emailMessageSendError(error));
		}

		dispatch(emailMessageSendResponse(response));
	};
};

export const sendDirectEmail = (body, to, subject) => {
	return async (dispatch, getState) => {
		if (!body || !to || !subject) {
			console.error('sendDirectEmail, missing required params');
			return false; // dispatch(commsUpdateError({ message: 'no id found to update user with' }));
		}
		const state = getState().comms;
		// if (state.isRequesting) {
		// 	const deferred = () => {
		// 		sendCustomEmailMessage(user, message);
		// 	};
		// 	dispatch(queueDeferredAction('comms', deferred));
		// 	return;
		// }
		dispatch(commsRequestStart());
		let response;
		let error;
		try {
			response = await axios.post('/comms/email/send?oType=directSend', {
				body,
				to,
				subject
			});
		} catch (err) {
			error = err;
		}

		// dispatch(commsRequestEnd());

		console.log('sendDirectEmail ::::: response, error');
		console.log(response, error);

		if (error) {
			return dispatch(emailMessageSendError(error));
		}

		dispatch(emailMessageSendResponse(response));
	};
};

export const addEmailTemplateLocalRecord = data => ({
	type: COMMS_ADD_LOCAL_RECORD,
	payload: { data, type: COMMS_TYPES.EMAIL_TEMPLATE }
});

export const updateEmailTemplateLocalRecord = (id, data) => ({
	type: COMMS_UPDATE_LOCAL_RECORD,
	payload: { id, data, type: COMMS_TYPES.EMAIL_TEMPLATE }
});

export const emailTemplatesLoaded = ({ templates }) => ({
	type: COMMS_LOADED,
	payload: { templates, type: COMMS_TYPES.EMAIL_TEMPLATE }
});

export const emailTemplateSaved = responseMsg => ({
	type: EMAIL_TEMPLATE_SAVED,
	payload: responseMsg
});

export const emailTemplateSaveError = responseMsg => ({
	type: EMAIL_TEMPLATE_SAVE_ERROR,
	payload: responseMsg
});

export const dropEmailTemplateLocalRecord = id => ({
	type: COMMS_DROP_LOCAL_RECORD,
	payload: { id }
});

export const emailTemplateDeleted = responseMsg => ({
	type: EMAIL_TEMPLATE_DELETED,
	payload: responseMsg
});

export const emailTemplateDeleteError = responseMsg => ({
	type: EMAIL_TEMPLATE_DELETE_ERROR,
	payload: responseMsg
});

export const emailTemplateClearSaveResult = () => ({
	type: EMAIL_TEMPLATE_SAVE_RESULT_CLEAR
});

export const emailTemplateClearDeleteResult = () => ({
	type: EMAIL_TEMPLATE_DELETE_RESULT_CLEAR
});

export const commsLoadError = error => ({
	type: COMMS_LOAD_ERROR,
	payload: { error }
});

export const commsAddError = error => ({
	type: COMMS_ADD_ERROR,
	payload: { error }
});

export const commsUpdateError = error => ({
	type: COMMS_UPDATE_ERROR,
	payload: { error }
});

export const commsRequestEnd = () => ({
	type: COMMS_REQUEST_END,
	payload: false
});

export const commsRequestStart = () => ({
	type: COMMS_REQUEST_START,
	payload: true
});

export const emailMessageSendResponse = response => ({
	type: SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE,
	payload: response
});

export const emailMessageSendError = error => ({
	type: SEND_CUSTOM_EMAIL_MESSAGE_RESPONSE_ERROR,
	payload: error
});
