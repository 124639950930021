import React, { useEffect, useState } from 'react';

// import styled from 'styled-components';
import { Spinner, Button, Flex, Box } from 'theme-ui';
import useEntries from '../../../hooks/useEntries';
import ImageDisplayer from '../../segments/ImageDisplayer';
import { ViewTable } from '../../ThemeUiStyled';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';

const EntriesView = props => {
	const { data, error } = props;
	const {
		selectedEntryId,
		getEntry,
		saveEntry,
		isProcessing,
		uiStatusMessageSaveResult,
		uiStatusSaveMessageConsumed
	} = useEntries({
		event: 'dress-a-like'
	});

	console.log('EntriesView');
	console.log(selectedEntryId, data, error);

	const handleApproveEntry = () => {
		if (data) {
			saveEntry(selectedEntryId, { isApproved: !data.isApproved });
		}
	};

	useEffect(() => {
		if (!data && selectedEntryId) {
			console.log('getEntry!!!!' + selectedEntryId);
			getEntry(selectedEntryId);
		}
	}, [data, selectedEntryId, getEntry]);

	if (!data) {
		if (error) {
			if (error.response.status === 404) {
				return <p>Item not found, please return to the list and try again.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Loading entry....</p>
			</>
		);
	}

	return (
		<div>
			<h2>Entry:</h2>
			<Flex>
				<Box>
					<ViewTable>
						<tbody>
							<tr>
								<td>Entry: </td>
								<td>{data.name}</td>
							</tr>
							<tr>
								<td>Email: </td>
								<td>{data.email}</td>
							</tr>
							<tr>
								<td>Location: </td>
								<td>
									{data.city}, {data.state}, {data.country}
								</td>
							</tr>
							<tr>
								<td>Entry date:</td>
								<td>{new Date(data.created).toUTCString()}</td>
							</tr>
							<tr>
								<td>Group ID: </td>
								<td>{data.groupId || ''}</td>
							</tr>
							<tr>
								<td>Likes / Votes: </td>
								<td>{data.likes || '0'}</td>
							</tr>
							<tr>
								<td>Dislikes: </td>
								<td>{data.dislikes || '0'}</td>
							</tr>
							<tr>
								<td colSpan='2'>
									<div
										style={{
											textAlign: 'left',
											paddingRight: '24px',
											marginTop: '20px',
											borderTop: '2px solid #ddd'
										}}>
										<p>
											This entry is{' '}
											{data.isApproved ? 'approved' : 'not approved'} to show
											on the public site
										</p>
										<Button
											onClick={handleApproveEntry}
											disabled={isProcessing || !data}>
											{data.isApproved ? 'Un-approve' : 'Approve'}
										</Button>
										<UiStatusMessageDisplay
											message={uiStatusMessageSaveResult}
											consumed={uiStatusSaveMessageConsumed}
										/>
									</div>
								</td>
							</tr>
							{/*
							<tr>
								<td>Age / Birthday: </td>
								<td>{data.birthday?.split('T').shift()}</td>
							</tr>
							<tr>
								<td>Email: </td>
								<td>{data.email}</td>
							</tr>
							<tr>
								<td>City: </td>
								<td>{data.city}</td>
							</tr>
							<tr>
								<td>State / Province: </td>
								<td>{data.state}</td>
							</tr>
							<tr>
								<td>Country: </td>
								<td>{data.country}</td>
							</tr>
							*/}
						</tbody>
						<colgroup>
							<col style={{ width: '120px' }}></col>
							<col style={{ width: '320px' }}></col>
						</colgroup>
					</ViewTable>
				</Box>
				<Box>
					<h3>Entry Image:</h3>
					{data.entry_photo?.fileData ? (
						<div style={{ width: '200px', maxWidth: '400px' }}>
							<img src={data.entry_photo.fileData} style={{ maxWidth: '100%' }} />
						</div>
					) : (
						<p>no image found</p>
					)}

					<h3>Entry Video link:</h3>
					{data.entry_video ? (
						<div style={{ width: '200px' }}>{data.entry_video}</div>
					) : (
						<p>no video link found</p>
					)}

					{/* <h3>Eligible Voting Categories:</h3>
					<p>
						<em>(TBD: show the categories here, only People's Choice Award)</em>
					</p> */}
				</Box>
			</Flex>
		</div>
	);
};

export default EntriesView;
