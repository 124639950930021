/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Button, Spinner } from 'theme-ui';
import useBrands from '../../../hooks/useBrands';
import DeleteModal from '../DeleteModal';
import UiStatusMessageDisplay from '../UiStatusMessageDisplay';
import { showCityState } from '../../../utils/datasource';
import { ListTable } from '../../ThemeUiStyled';

const BrandsList = props => {
	const { data, error } = props;
	const {
		selectBrand,
		deleteBrand,
		uiStatusMessageDeleteResult,
		uiStatusDeleteMessageConsumed
	} = useBrands();
	const history = useHistory();

	const [brandToDelete, setBrandToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const { theme } = useThemeUI();

	if (!data?.length) {
		if (error) {
			if (error.response.status === 404) {
				return <p>No data found, add something to the list.</p>;
			}
			return <p>Oops, error!</p>;
		}
		return (
			<>
				<Spinner />
				<p>Getting brands....</p>
			</>
		);
	}

	const viewSelectedItem = item => {
		console.log('viewSelectedItem');
		selectBrand(item._id);
		history.push('/data/brands/view/' + item._id);
	};

	const editSelectedItem = item => {
		console.log('editSelectedItem');
		selectBrand(item._id);
		history.push('/data/brands/edit/' + item._id);
	};

	const deleteSelectedItem = item => {
		setBrandToDelete(item);
		setShowDeleteModal(true);
	};

	const rejectDelete = () => {
		setBrandToDelete(null);
		setShowDeleteModal(false);
	};

	const confirmDelete = () => {
		brandToDelete && deleteBrand(brandToDelete._id);
		setBrandToDelete(null);
		setShowDeleteModal(false);
	};

	const listActionSx = {
		ml: '0.5em',
		my: '0.5em',
		p: '2px 5px',
		fontSize: '12px',
		backgroundColor: theme.colors.secondary,
		color: theme.colors.background
	};

	const getListActions = item => {
		return (
			<>
				<Button
					sx={listActionSx}
					onClick={() => {
						viewSelectedItem(item);
					}}>
					View
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						editSelectedItem(item);
					}}>
					Edit
				</Button>
				<Button
					sx={listActionSx}
					onClick={() => {
						deleteSelectedItem(item);
					}}>
					Delete
				</Button>
				<DeleteModal
					show={showDeleteModal}
					objName='brand'
					confirm={confirmDelete}
					reject={rejectDelete}
				/>
			</>
		);
	};

	return (
		<div>
			<UiStatusMessageDisplay
				message={uiStatusMessageDeleteResult}
				consumed={uiStatusDeleteMessageConsumed}
			/>
			<ListTable>
				<thead>
					<tr>
						<td>Brand</td>
						<td>Contact</td>
						<td>City / State</td>
						<td></td>
					</tr>
				</thead>
				<tbody>
					{data.map((item, idx) => {
						return (
							<tr key={idx}>
								<td>{item.name}</td>
								<td>{item.contact}</td>
								<td>{showCityState(item)}</td>
								<td>{getListActions(item)}</td>
							</tr>
						);
					})}
				</tbody>
			</ListTable>
		</div>
	);
};

export default BrandsList;
